<div class="flex-column height-100">
    <upi-spinner></upi-spinner>
    <ng-container *ngIf="!!currentUser && !isLogin()">
      <header></header>
      <main-menu></main-menu>
    </ng-container>
    <div class="main-content height-100">
        <router-outlet></router-outlet>
    </div>
</div>
