import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogResultEnum, SpinnerService } from'@upi/common';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-unhandled-error-details',
  templateUrl: './unhandled-error-details.component.html',
  styleUrls: ['./unhandled-error-details.component.scss']
})
export class UnhandledErrorDetailsComponent implements OnInit {

  private loggerName = "UnhandledErrorComponent";

  showDetails: boolean = true;
  dialogResult = DialogResultEnum;
  data: any;

  constructor(
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) data : any,
    private spinnerService : SpinnerService
  ) {
    this.data = data;
  }

  ngOnInit() {
    this.spinnerService.hide();
  }

}
