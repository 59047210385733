import { Component, Input, OnInit } from '@angular/core';
import { PresentationItem } from '@upi/data-services';

@Component({
  selector: 'presentation-item',
  templateUrl: './presentation-item.component.html',
  styleUrls: ['./presentation-item.component.scss']
})
export class PresentationItemComponent implements OnInit {

  @Input() data! : PresentationItem;
  
  constructor() { }

  ngOnInit(): void {
  }

}
