import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../guards/user.guard';
import { AgroConsultingComponent } from './components/agro-consulting/agro-consulting.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { FaqComponent } from './components/faq/faq.component';
import { InfoComponent } from './components/info/info.component';
import { OrderServiceComponent } from './components/order-service/order-service.component';
import { TeamComponent } from './components/team/team.component';

const routes: Routes = [
  {
    path: 'services', component: ContactsComponent, children: [
      { path: '', redirectTo: 'orderservice', pathMatch: 'full' },
      { path: 'orderservice', pathMatch: 'full', component: OrderServiceComponent, data: { title: 'Замовлення послуг' }, canActivate: [UserGuard] },
      { path: 'agroconsulting', pathMatch: 'full', component: AgroConsultingComponent,  data: { title: 'Консультація агронома' }, canActivate: [UserGuard] },
      { path: 'faq', pathMatch: 'full', component: FaqComponent, data: { title: 'Часті питання' }, canActivate: [UserGuard] },
      { path: 'info', pathMatch: 'full', component: InfoComponent, data: { title: 'Інформація для вас ' }, canActivate: [UserGuard] },
      { path: 'team', pathMatch: 'full', component: TeamComponent, data: { title: 'Наша команда' }, canActivate: [UserGuard] },
      { path: 'contacts', pathMatch: 'full', component: ContactComponent, data: { title: 'Контакти' }, canActivate: [UserGuard] },
    ], canActivate: [UserGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactsRoutingModule { }
