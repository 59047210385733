import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Consignment, Contragent, Company } from '@upi/data-services';
import { ConsignmentsDataService } from '../../../data-services/consignments-data.service';
import { NGXLogger } from 'ngx-logger';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { ComponentBase } from '../../../app-common/types/component-base';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { AppConfig } from '../../../app-common/types/app-config';
import { NgForm } from '@angular/forms';
import { GeneralDataService } from '../../../data-services/general-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageGaleryPreviewDialogComponent } from '../../../app-common/components/image-galery-preview-dialog/image-galery-preview-dialog.component';
import { UserService } from 'src/app/app-common/services/user.service';
import { LocalDataStorageService } from 'src/app/app-common/services/local-data-storage.service';

export class ConsignmentsListFilter {
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  contractor!: string;
}

@Component({
  selector: 'app-consignments-list',
  templateUrl: './consignments-list.component.html',
  styleUrls: ['./consignments-list.component.scss']
})
export class ConsignmentsListComponent extends ComponentBase implements OnInit, AfterViewInit {

  // DropDowns
  contragents: string[] = [];

  minDate: Date = new Date(2000, 1, 1);
  filter!: ConsignmentsListFilter;
  data!: Consignment[];

  displayedRow1: string[] = ['number', 'date', 'contractor', 'grossWeight', 'tareWeight', 'netWeight', 'vehicle', 'driver', /*'driversPhone',*/ 'analysis-header-group'];
  displayedRow2: string[] = ['contamination', 'greenMass', 'putridity', 'sugarContent', 'link'];
  displayedColumns: string[] = ['number', 'date', 'contractor', 'grossWeight', 'tareWeight', 'netWeight', 'vehicle', 'driver', /*'driversPhone',*/ 'contamination', 'greenMass', 'putridity', 'sugarContent', 'link'];
  dataSource = new MatTableDataSource<Consignment>();

  @ViewChild('frmSearch', { read: NgForm }) frmSearch: any;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private consignmentsDataService: ConsignmentsDataService,
    private generalDataService: GeneralDataService,
    private dialog: MatDialog,
    private userService : UserService,
    private localDataStorageService : LocalDataStorageService,

  ) {

    super(logger, appConfig, 'ConsignmentsListComponent');

    this.filter = new ConsignmentsListFilter();
    const date = new Date();
    this.filter.dateFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
    this.filter.dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

  }

  ngOnInit(): void {
    this.logger.trace(this.loggerName, 'ngOnInit');
  }

  ngAfterViewInit() {
    this.loadContragents();
    this.loadData(this.filter);
    this.dataSource.sort = this.sort;
  }

  loadContragents() {

    this.generalDataService.getOrganizationChart(this.userService.contractorGuid).subscribe((contragent: Contragent) => {

      this.contragents = [];

      contragent.subordinateCompanies.forEach((el: Company) => {
        this.contragents.push(el.name);
      });

    })

  }

  loadData(filter: ConsignmentsListFilter) {

    this.logger.trace(this.loggerName, "loadData");

    if (!this.frmSearch?.form?.valid) {
      return;
    }

    this.consignmentsDataService.getTtnList(filter.dateFrom, filter.dateTo, this.localDataStorageService.productionYear, this.userService.contractorGuid).subscribe((data: Consignment[]) => {

      this.data = data;

      this.updateDataTable(data);

    });

  }

  updateDataTable(data: Consignment[]) {

    this.dataSource = new MatTableDataSource(data);
    this.dataSource.filterPredicate = (data, filter) => this.filterData(data, filter);
    this.dataSource.sort = this.sort;

  }

  private filterData(data: Consignment, filter: string): boolean {

    let result = true;

    let _filter: ConsignmentsListFilter = JSON.parse(filter);

    if (result && _filter.contractor) {
      result = result && (data.contractor === _filter.contractor);
    }

    return result;

  }

  applyFilter(filter: ConsignmentsListFilter) {
    this.dataSource.filter = JSON.stringify(filter);
  }

  dateFromChanged(e: any, filter: ConsignmentsListFilter) {

    this.filter.dateFrom = e.value;

    if (e.value && filter?.dateTo?.getTime() < e.value.getTime()) {
      let date = new Date(e.value);
      date.setDate(date.getDate() + 7);
      this.filter.dateTo = new Date(date);
      this.filter.dateTo.setHours(23);
      this.filter.dateTo.setMinutes(59);
      this.filter.dateTo.setSeconds(59);
    }

    this.loadData(filter);

  }

  dateToChanged(e: any, filter: ConsignmentsListFilter) {

    this.filter.dateTo = e.value;
    this.filter.dateTo.setHours(23);
    this.filter.dateTo.setMinutes(59);
    this.filter.dateTo.setSeconds(59);

    if (e.value && e.value.getTime() < filter?.dateFrom?.getTime()) {
      let date = new Date(e.value);
      date.setDate(date.getDate() - 7);
      this.filter.dateFrom = new Date(date);
    }

    this.loadData(filter);

  }

  openPictures(item: Consignment) {

    this.dialog.open(ImageGaleryPreviewDialogComponent, {
      width: '800px',
      hasBackdrop: false,
      disableClose: false,
      data: {
        images: item.imagesUrls
      }
    });
  }

}
