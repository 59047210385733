import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfig } from '../../../app-common/types/app-config';
import { ComponentBase } from '../../../app-common/types/component-base';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { ImageService } from '../../../services/image.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent extends ComponentBase implements OnInit {

  @Input() allow:boolean = false;
  
  private _url!: string;
  @Input()
  get url():string  {
    return this._url;
  }
  set url(value: string) {
    this._url = value;
    this.loadImage(value);
  }

  private _imageDataLocalUrl! :SafeResourceUrl; 
  get imageDataLocalUrl() : SafeResourceUrl {
    return this._imageDataLocalUrl;
  }

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private domSanitizer: DomSanitizer,
    private imageService : ImageService,
  ) {
    super(logger, appConfig, 'ImagePreviewComponent');
  }

  ngOnInit(): void {
  }

  private loadImage(url :string) {

    this.logger.trace(this.loggerName, 'loadImage', url);
    
    let imageUrl = url;
    
    if (!imageUrl) {
      imageUrl = '/ph/P00003009/170506_Брутто_1_.jpg';
    }

    this.imageService.getImage(imageUrl).subscribe(
      (content: any) => {
        let data = new Blob([content], { type: content.type });
        this._imageDataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(data));
      },
      (error) => {
        this.logger.error(this.loggerName, error);
      }
    );
  }


}
