<div class="order-service-wrapper">

    <div class="services-wrapper">
        <div class="service-wrapper" *ngFor="let svc of services">
            <div class="service" (click)="orderService(svc)">
                <img [src]="svc.image" />
                <div class="title">
                    <span>{{svc.title}}</span>
                </div>
            </div>
        </div>
    </div>

</div>