import { Injectable } from '@angular/core';
import { Ability, AbilityBuilder } from '@casl/ability';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, UserPermission } from '@upi/data-services';
import { LocalDataStorageService } from './local-data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private loggerName = 'UserService';

  private userLoadedInternal : BehaviorSubject<User>;
  
  public user$  : Observable<User>;
  
  public get user() : User{
    return this.userLoadedInternal.value;
  }

  constructor(
    private logger: NGXLogger,
    private localDataStorageService: LocalDataStorageService,
    private ability: Ability

  ) {
    const empty : any = null;
    this.userLoadedInternal = new BehaviorSubject<User>(empty);
    this.user$ = this.userLoadedInternal.asObservable();
    this.setUserInfo(this.localDataStorageService.getUser());
  }

  setUserInfo(user: User) {

    this.logger.trace(this.loggerName, "setUserInfo", user);

    if (user?.userName === undefined) {
      this.logger.trace(this.loggerName, "userInfo is invalid");
      return;
    }

    this.localDataStorageService.setUser(user);

    if (!!user) {
      this.updateAbility(user.isSuperUser, user.permissions);
    } else {
      this.ability.update([]);
    }

    this.userLoadedInternal.next(user);

  }

  resetuserInfo() {

    this.localDataStorageService.removeUser();

    this.ability.update([]);
    
    this.userLoadedInternal.next(new User(null, '', undefined));

  }

  private updateAbility(isAdmin: boolean, permissions: UserPermission[]) {

    const { can, rules } = new AbilityBuilder<Ability>(Ability);

    if (isAdmin) {
      can('manage', 'all');
    } else {
      if (Array.isArray(permissions) && permissions?.length > 0) {
        permissions.forEach(item =>
          item.permissions.forEach(permission => {
            can(permission, item.subject)
          })
        );
      }
      else {
        this.ability.update([]);
      }
    }

    // if (specialStatus) {
    //   can('view', 'special.subject');
    // }

    this.ability.update(rules);

  }

  get contractorGuid() : string | undefined {
    
    const user = this.localDataStorageService.getUser();
      
    return user?.contractor;

  }

  

}
