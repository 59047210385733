import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../guards/user.guard';
import { OrgChartComponent } from './components/org-chart/org-chart.component';
import { OrganizationComponent } from './components/organization/organization.component';

const routes: Routes = [
  {
    path: 'organization', pathMatch: 'prefix', component: OrganizationComponent, children: [
      { path: 'orgchart', pathMatch: 'full', component: OrgChartComponent, data: { title: 'Організація' }, canActivate: [UserGuard] },
      { path: '', redirectTo: 'orgchart', pathMatch: 'full' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationRoutingModule { }
