<div class="login-wrapper">
    <div>
        <div class="error-wrapper" *ngIf="!!error && !mode">
            <ng-container [ngSwitch]="error.status">
                <ng-container *ngSwitchCase="400">
                    Пароль було видано менше хвилини тому
                    <div style="display: none;">
                        {{error.error}}
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="401">
                    Номер телефону або пароль невірні
                </ng-container>
                <ng-container *ngSwitchDefault>
                    Помилка входу в систему. Зверніться в підтримку.
                </ng-container>
            </ng-container>
        </div>
        <div class="error-wrapper" *ngIf="!!error && mode">
            <ng-container [ngSwitch]="error.status">
                <ng-container *ngSwitchCase="401">
                    Номер телефону або пароль невірні
                </ng-container>
                <ng-container *ngSwitchDefault>
                    Помилка входу в систему. Зверніться в підтримку.
                </ng-container>
            </ng-container>
        </div>
        <form #frmLogin="ngForm" novalidate>
            <ng-container *ngIf="step===1">
                <div>
                    <mat-form-field class="width-100">
                        <mat-label>Номер телефону:</mat-label>
                        <input type="text" class="userlogin" matInput #userLogin="ngModel" name="userLogin"
                            autocomplete="userlogin" prefix="38" mask="(000)000-00-00" [showMaskTyped]="true"
                            [(ngModel)]="model.phone" required pattern="0\d\d\d{3}(\d{2}){2}" />
                        <mat-error *ngIf="userLogin.errors?.required">Обов'язкове поле</mat-error>
                        <mat-error *ngIf="userLogin.errors?.pattern">Невірний формат номеру 380YYXXXXXXX</mat-error>
                    </mat-form-field>
                </div>
                <div class="button-wrapper">
                    <button type="button" mat-flat-button color="primary"
                        (click)="!userLogin.errors && requestCode(model.phone)"
                        [disabled]="!!userLogin.errors || formModeWaitForCode">Надіслати код <span
                            *ngIf="formModeWaitForCode">({{counter}})</span></button>
                </div>
                <div>
                    <pin-control name="userPassword" #userPassword="ngModel" [length]="4" [hidden]="true"
                        [charPattern]="charPattern" [(ngModel)]="model.password" [required]="true">
                    </pin-control>
                    <!-- <mat-error *ngIf="userPassword.errors?.formError">Код має містити лише цифри</mat-error> -->
                </div>
                <div class="button-wrapper">
                    <button type="submit" mat-stroked-button #btnLogin mat-button
                        (click)="frmLogin.form.valid && login(model, otcResponse)"
                        [disabled]="!frmLogin.form.valid">Вхід</button>
                </div>
            </ng-container>
            <ng-container *ngIf="step===2">
                <mat-form-field class="width-100">
                    <mat-label>Контрагент</mat-label>
                    <mat-select name="contractor" [(ngModel)]="model.contractorGuid" [required]="true">
                        <mat-option *ngFor="let _contractor of maincontractors" [value]="_contractor.guid">
                            {{_contractor.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="button-wrapper">
                    <button type="submit" mat-stroked-button #btnLogin mat-button
                        (click)="frmLogin.form.valid && selectContragent(model.contractorGuid)"
                        [disabled]="!frmLogin.form.valid">Обрати</button>
                </div>
            </ng-container>
        </form>
    </div>
</div>