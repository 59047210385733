import { Component, Inject, OnInit } from '@angular/core';
import { TeamMembers } from '@upi/data-services';
import { AppConfig } from '../../../app-common/types/app-config';
import { ComponentBase } from '../../../app-common/types/component-base';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { ContactsService } from '../../../data-services/contacts.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent extends ComponentBase implements OnInit {

  contactInfoGroups!: TeamMembers[];

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private contactsService: ContactsService,
  ) {
    super(logger, appConfig, 'TeamComponent');
  }

  ngOnInit(): void {
    this.load();
  }

  load() {

    this.logger.trace(this.loggerName, 'load');

    this.contactsService.getTeamMembersList().subscribe((data) => {
      this.contactInfoGroups = data;
    });

  }

}
