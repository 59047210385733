import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
  name: 'number'
})
export class NumberPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: string | number, digitsInfo: string, locale = 'uk'): string | null {
    const enResult = this.decimalPipe.transform(value, digitsInfo, locale);
    return enResult ? enResult.replace(',', '.') : null;
  }
}
