import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UpiService } from '@upi/data-services';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../app-common/services/user.service';
import { AppConfig } from '../app-common/types/app-config';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { CommonDataService } from './common-data.service';

@Injectable({
  providedIn: 'root'
})
export class UpiServicesService extends CommonDataService {

  constructor(
    logger: NGXLogger,
    @Inject(APP_CONFIG) appConfig: AppConfig,
    protected userService: UserService,
    private httpClient: HttpClient,
  ) {
    super(logger, appConfig, userService);
    this.loggerName = 'UpiServiceService';
  }

  getList(): Observable<UpiService[]> {

    this.logger.trace(this.loggerName, "getList");

    return this.httpClient.get('assets/contacts/services.json').pipe(
      map((data: any) => { return data ? data.map((el: any) => new UpiService(el)) : [] })
    );

  }
}