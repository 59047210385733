<div class="container">
  <div class="page-header">
    <div class="page-title">
      <h2>Реєстр накладних та аналізів</h2>
    </div>
  </div>
  <form #frmSearch="ngForm" id="frmSearch" (ngSubmit)="loadData(filter)" novalidate>
    <div>
      <!-- appearance="fill" -->
      <mat-form-field (click)="pickerFrom.open()">
        <mat-label>Початкова дата</mat-label>
        <input name="dateFrom" matInput [matDatepicker]="pickerFrom" [readonly]="true" [value]="filter.dateFrom"
          required [min]="minDate" (dateInput)="dateFromChanged($event, filter)">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field (click)="pickerTo.open()">
        <mat-label>Кінцева дата</mat-label>
        <input name="dateTo" matInput [matDatepicker]="pickerTo" [readonly]="true" [value]="filter.dateTo" required
          [min]="filter.dateFrom" (dateInput)="dateToChanged($event, filter)">
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>

      <!-- <mat-date-range-input [rangePicker]="picker">
          <input matStartDate name="dateFrom" #dateFrom="ngModel" [readonly]="true" [(ngModel)]="filter.dateFrom"
            required [min]="minDate">
          <input matEndDate name="dateTo" #dateTo="ngModel" [readonly]="true" [(ngModel)]="filter.dateTo" required
            [min]="dateFrom.value">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker (closed)="loadData(filter)"></mat-date-range-picker>
        <mat-error *ngIf="dateFrom.errors?.required">222</mat-error>
        <mat-error *ngIf="dateTo.errors?.required">333</mat-error> -->

      <!-- appearance="fill" -->
      <mat-form-field>
        <mat-label>Юридична особа</mat-label>
        <mat-select name="contragent" [(ngModel)]="filter.contractor" (ngModelChange)="applyFilter(filter)">
          <mat-option value=''>Всі</mat-option>
          <mat-option *ngFor="let contragent of contragents" [value]="contragent">
            {{contragent}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <table mat-table [dataSource]="dataSource" matSort class="width-100 mat-elevation-z8">

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">Номер ТТН</th>
      <td mat-cell *matCellDef="let element">{{element.number}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">Дата</th>
      <td mat-cell *matCellDef="let element">{{element.date | date:'dd.MM.yyyy hh:mm:ss'}}</td>
    </ng-container>

    <ng-container matColumnDef="contractor">
      <th mat-header-cell class="right-align" *matHeaderCellDef mat-sort-header rowspan="2">Юридична особа</th>
      <td mat-cell class="left-align" *matCellDef="let element">{{element.contractor}}</td>
    </ng-container>

    <ng-container matColumnDef="grossWeight">
      <th mat-header-cell class="right-align" *matHeaderCellDef mat-sort-header rowspan="2">Вага брутто, т.</th>
      <td mat-cell class="right-align" *matCellDef="let element">{{element.grossWeight | number : '1.2-2'}}</td>
    </ng-container>

    <ng-container matColumnDef="tareWeight">
      <th mat-header-cell class="right-align" *matHeaderCellDef mat-sort-header rowspan="2">Вага тари, т.</th>
      <td mat-cell class="right-align" *matCellDef="let element">{{element.tareWeight | number : '1.2-2'}}</td>
    </ng-container>

    <ng-container matColumnDef="netWeight">
      <th mat-header-cell class="right-align" *matHeaderCellDef mat-sort-header rowspan="2">Вага нетто, т.</th>
      <td mat-cell class="right-align" *matCellDef="let element">{{element.netWeight | number : '1.2-2'}}</td>
    </ng-container>

    <ng-container matColumnDef="vehicle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">Автомобіль</th>
      <td mat-cell *matCellDef="let element">{{element.vehicle}}</td>
    </ng-container>

    <ng-container matColumnDef="driver">
      <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">ПІП водія<br />Телефон водія</th>
      <td mat-cell *matCellDef="let element">
        <div>{{element.driver}}</div>
        <div>
          <a *ngIf="element.driversPhone" href="tel:+{{element.driversPhone}}">
            {{element.driversPhone | phoneNumber}}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="driversPhone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">Телефон водія</th>
      <td mat-cell *matCellDef="let element">
        <a *ngIf="element.driversPhone" href="tel:+{{element.driversPhone}}">
          {{element.driversPhone | phoneNumber}}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="analysis-header-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="5">Результати аналізів</th>
    </ng-container>

    <ng-container matColumnDef="contamination">
      <th class="text-small" mat-header-cell *matHeaderCellDef>Забрудненість, %</th>
      <td mat-cell *matCellDef="let element"> {{element.contamination | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="greenMass">
      <th class="text-small" mat-header-cell *matHeaderCellDef>Зелена маса, %</th>
      <td mat-cell *matCellDef="let element"> {{element.greenMass | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="putridity">
      <th class="text-small" mat-header-cell *matHeaderCellDef>Гнильність, %</th>
      <td mat-cell *matCellDef="let element"> {{element.putridity | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="sugarContent">
      <th class="text-small" mat-header-cell *matHeaderCellDef>Цукристість, %</th>
      <td mat-cell *matCellDef="let element"> {{(element.sugarContent || 0) | number : '1.2-2'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="link">
      <th class="text-small" mat-header-cell *matHeaderCellDef>Фото</th>
      <td mat-cell *matCellDef="let item">
        <button type="button" mat-icon-button matTooltip="Відкрити фото" [disabled]="!item?.images?.length"
          (click)="openPictures(item)">
          <mat-icon>file_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedRow1; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedRow2; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<!--<div><pre>{{data|json}}</pre></div>-->