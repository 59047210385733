import { INGXLoggerConfig } from "ngx-logger";
import { AppConfigExternalResources } from "./app-config-external-resources";
import { AppConfigFakeServices } from "./app-config-fake-services";
import { AppConfigGoogleMaps } from "./app-config-google-maps";

/**
 * 
 * otcRequestDelayMinutes = One Time Code Request Delay in Minutes
 * vehicleLocationDelay = Delay for received coordinates .... 
 */
export class AppConfig {

    environment!: string;
    title!: string;
    logo!: string;
    dataServer!: string;

    startYear! : number;

    externalResources!: AppConfigExternalResources;

    otcRequestDelayMinutes!: number;

    dateFormat!: string;

    hotlinePhone!: string;

    vehicleLocationDelay!: number;
    googleMaps!: AppConfigGoogleMaps;
    
    logging!: INGXLoggerConfig;

    fakeServices!: AppConfigFakeServices;

    static isExternalResource(value: string, values: string[]): boolean {
        return values.findIndex(_value => value.indexOf(_value) !== -1) !== -1;
    }

}
