import { HttpClient } from '@angular/common/http';
import { CommonDataService } from './common-data.service';
import { AppConfig } from '../app-common/types/app-config';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { Inject, Injectable } from '@angular/core';
import { BenefitItem, ContactInfo, PresentationItem, TeamMembers, TeamMember, TeamMemberData } from '@upi/data-services';
import { UserService } from '../app-common/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsService extends CommonDataService {

  constructor(
    logger: NGXLogger,
    @Inject(APP_CONFIG) appConfig: AppConfig,
    protected userService: UserService,
    private httpClient: HttpClient
  ) {
    super(logger, appConfig, userService);
    this.loggerName = 'ContactsService';
  }

  getBenefitsList(): Observable<BenefitItem[]> {

    this.logger.trace(this.loggerName, "getBenefitsList");

    return this.httpClient.get('assets/contacts/benefits.json').pipe(
      map((data: any) => { return data ? data.map((el: any) => new BenefitItem(el)) : [] })
    );

  }

  getTeamMembersList(): Observable<TeamMembers[]> {

    this.logger.trace(this.loggerName, "getTeamMembersList");

    return this.httpClient.get('assets/contacts/teamMembers.json').pipe(
      map((data: any) => { return data ? data.map((el: any) => new TeamMembers(el)) : [] })
    );

  }

  getTeamMemberList(): Observable<TeamMember[]> {

    this.logger.trace(this.loggerName, "getTeamMembersList");

    return this.httpClient.get('assets/contacts/teamMembers.json').pipe(
      map((data: any) => { return data ? data.map((el: any) => new TeamMember(el)) : [] })
    );

  }

  getTeamMemberDataList(): Observable<TeamMemberData[]> {

    this.logger.trace(this.loggerName, "getTeamMembersList");

    return this.httpClient.get('assets/contacts/teamMembers.json').pipe(
      map((data: any) => { return data ? data.map((el: any) => new TeamMemberData(el)) : [] })
    );

  }

  getPresentationsList(): Observable<PresentationItem[]> {


    this.logger.trace(this.loggerName, "getPresentationsList");

    return this.httpClient.get('assets/contacts/presentations.json').pipe(
      map((data: any) => { return data ? data.map((el: any) => new PresentationItem(el)) : [] })
    );

  }

  getContactsList(): Observable<ContactInfo[]> {

    this.logger.trace(this.loggerName, "getContactsList");

    return this.httpClient.get('assets/contacts/contacts.json').pipe(
      map((data: any) => { return data ? data.map((el: any) => new ContactInfo(el)) : [] })
    );

  }

}

