import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CompanyContract } from '@upi/data-services';
import { AppConfig } from '../../..//app-common/types/app-config';
import { ComponentBase } from '../../../app-common/types/component-base';
import { ContractsDataService } from '../../../data-services/contracts-data.service';
import { NGXLogger } from 'ngx-logger';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { Contract, Company } from "@upi/data-services/";
import { ImageService } from '../../../services/image.service';
import { UserService } from 'src/app/app-common/services/user.service';
import { LocalDataStorageService } from 'src/app/app-common/services/local-data-storage.service';

@Component({
  selector: 'app-contracts-list',
  templateUrl: './contracts-list.component.html',
  styleUrls: ['./contracts-list.component.scss']
})
export class ContractsListComponent extends ComponentBase implements OnInit {

  data!: CompanyContract[];

  // 'contractor', 
  displayedColumns: string[] = ['name', 'number', 'date', 'dateEnd', 'type', 'sugarQuantityFrom1Tone', 'link'];
  dataSource = new MatTableDataSource<CompanyContract | Contract>();

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private contractsDataService: ContractsDataService,
    private imageService: ImageService,
    private userService: UserService,
    private localDataStorageService: LocalDataStorageService,

  ) {
    super(logger, appConfig, 'ContractsListComponent');
  }

  ngOnInit(): void {
    this.logger.trace(this.loggerName, 'ngOnInit');
    this.loadData(this.localDataStorageService.productionYear, this.userService.contractorGuid);
  }

  loadData(productionYear, contractorGuid) {

    this.logger.trace(this.loggerName, "loadData");

    this.contractsDataService.getList(productionYear, contractorGuid).subscribe((data: CompanyContract[]) => {
      this.data = data;
      this.updateDataTable(this.data);
    })

  }

  updateDataTable(data: CompanyContract[]) {
    const flatData = data.map(company => [company, company.contracts as Contract[]]).flat(2);
    this.dataSource = new MatTableDataSource(flatData);
    this.dataSource.sort = this.sort;
  }

  isCompany(index: number, item: CompanyContract | Contract) {
    return item.hasOwnProperty('contracts');
  }

  isContract(index: number, item: CompanyContract | Contract) {
    return !item.hasOwnProperty('contracts');
  }

  changeVisibility(company: Company) {

    this.logger.trace(this.loggerName, 'changeVisibility', company);

    company.showContracts = !company.showContracts;
    company.contracts.forEach(contract => {
      contract.isVisible = !contract.isVisible;
    });

  }


  //#region  Images

  private defaultBlobType = 'application/octet-stream';

  loadImage(contract: Contract, index: number) {

    this.logger.trace(this.loggerName, 'loadImage', contract, index);

    this.imageService.getImage(contract.images[index]).subscribe(
      (content: any) => {
        this.openFile(content);
      },
      (error) => {
        this.logger.error(this.loggerName, error);
      }
    );
  }

  openFile(blob) {

    const url = window.URL.createObjectURL(blob);

    window.open(url, '_blank');

  }

  //#endregion

}
