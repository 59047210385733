import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultEnum } from '@upi/common';
import { AppConfig } from '../../../app-common/types/app-config';
import { ComponentBase } from '../../../app-common/types/component-base';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { NGXLogger } from 'ngx-logger';
import { OrderServiceDialogComponent } from '../order-service-dialog/order-service-dialog.component';
import { UpiService } from '@upi/data-services';
import { UpiServicesService } from '../../../data-services/upi-service.service';

@Component({
  selector: 'app-order-service',
  templateUrl: './order-service.component.html',
  styleUrls: ['./order-service.component.scss']
})
export class OrderServiceComponent extends ComponentBase implements OnInit {

  services: UpiService[] = [];

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private dialog: MatDialog,
    private upiService: UpiServicesService,
  ) {
    super(logger, appConfig, 'OrderServiceComponent');
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.upiService.getList().subscribe((data: UpiService[]) => {
      this.services = data;
    });
  }


  orderService(service: UpiService) {

    this.logger.trace(this.loggerName, 'orderService', service);

    this.dialog.open(OrderServiceDialogComponent, {
      width: '560px',
      disableClose: false,
      data: {
        orderType: service.name,
        orderName: service.title
      }
    }).afterClosed().subscribe((result) => {

      if (result?.dialogResult == DialogResultEnum.ok) {
        alert('Запит відправлено!');
      }

    });
  }

}
