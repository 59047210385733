<form #form="ngForm" novalidate>
    <div mat-dialog-title>
        <h2>Вибір контрагента</h2>
        <button type="button" mat-icon-button class="close-button" color="primary" aria-label="Close"
            [mat-dialog-close]="{dialogResult : dialogResult.cancel}">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div class="contragent-selector-dialog-wrapper">
            <mat-form-field class="width-100">
                <mat-label>Контрагент</mat-label>
                <mat-select name="contractor" [(ngModel)]="contractorGuid" [required]="true">
                    <mat-option *ngFor="let _contractor of maincontractors" [value]="_contractor.guid">
                        {{_contractor.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions>
        <div>
            <button type="button" mat-button [mat-dialog-close]="{dialogResult : dialogResult.cancel}"
                cdkFocusInitial>Закрити</button>
        </div>
        <div>
            <button type="button" mat-button [disabled]="form.form.invalid"
                [mat-dialog-close]="{dialogResult : dialogResult.ok, contractor : contractorGuid}">Обрати</button>
        </div>
    </div>
</form>