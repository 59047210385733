import { Inject, Injectable } from '@angular/core';
import { UserService } from '../app-common/services/user.service';
import { AppConfig } from '../app-common/types/app-config';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  protected loggerName = 'CommonDataService';

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig : AppConfig,
    protected userService : UserService
  ) {
    this.appConfig = appConfig;
  }

  get dataSeverUrl(): string {
    return `${this.appConfig?.dataServer}?`;
  }

}
