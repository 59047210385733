import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, FormControl, NgForm, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

enum InputState {
  ready = 0,
  reset = 1
}

class PinElement {
  index !: number;
  element !: HTMLInputElement;
  value !: string;
  constructor(index: number) {
    this.index = index;
  }
}

@Component({
  selector: 'pin-control',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PinComponent,
      multi: true
    }
  ]
})
export class PinComponent implements OnInit, AfterViewInit, OnChanges, AfterViewChecked, ControlValueAccessor {

  private loggerName = 'PinComponent';

  @Input() hidden: boolean = false;
  @Input() charPattern: RegExp = /\d/;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;

  @Input()
  get length(): number {
    return this.pins.length;
  }
  set length(value: number) {
    for (let i = 0; i < value; i++) {
      this.pins.push(new PinElement(i));
    }
  }

  public pins: PinElement[] = [];
  private get pin(): string {
    return this.pins.map(el => el.value).join("");
  }

  @ViewChildren('input') inputsList !: QueryList<ElementRef>;
  @ViewChild('form') formEl: NgForm | null = null;

  constructor(
    private logger: NGXLogger,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let i = 0;
    this.inputsList.forEach((item) => {
      this.pins[i].element = item.nativeElement;
      i++;
    });
  }

  ngAfterViewChecked(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  onChange = (_: any) => {
  };

  onTouched = (_: any) => {
  };

  writeValue(value: string): void {

    // this.logger.trace(this.loggerName, "writeValue", value);

    if (!value) {
        this.pins.forEach(el=>el.value = '');
    } else {
      this.pins.forEach((el,index)=>el.value = value[index]);
    }

    // tbd: We never set Pin from code
    // user must enter it manually

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(c: FormControl) {

    this.logger.trace(this.loggerName, "validate", c);

    const formError = {
      formError: {
        required: this.required,
        given: c.value
      }
    };

    if (!this.formEl?.form?.valid) {
      return formError;
    }

    if (!this.required) {
      return null;
    }

    const requiredError = {
      requiredError: {
        required: this.required,
        given: c.value
      }
    };

    if (!c.value) {
      return requiredError;
    }

    return null;

  }

  private triggerChanges() {
    this.onChange(this.pin);
  }

  private triggerTouched() {
    this.onChange(this.pin);
  }

  onInput(e: any, index: number) {

    this.logger.trace(this.loggerName, "OnInput", e, index);

    if (e.data) {

      if (index + 1 < this.length) {
        this.pins[index + 1].element.focus();
      } 

    }

    this.triggerChanges();
    this.triggerTouched();

  }

}
