import { Inject } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { AppConfig } from "./app-config";
import { APP_CONFIG } from "./injection-tokens";

export class ComponentBase {

    protected loggerName : string = 'ComponentBase';
    protected appConfig!: AppConfig;

    constructor(
        protected logger: NGXLogger,
        @Inject(APP_CONFIG) appConfig : AppConfig,
        loggerName : string,
    ) {
        this.appConfig = appConfig;
        this.loggerName = loggerName;
    }


}
