<form #form="ngForm" novalidate (ngSubmit)="form.form.valid && makeOrder(model)">
    <div mat-dialog-title>
        <h2>{{data.orderName}}</h2>
        <button type="button" mat-icon-button class="close-button" color="primary" aria-label="Close"
            [mat-dialog-close]="{dialogResult : dialogResult.cancel}">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div class="order-service-dialog-wrapper">
            <div>
                <mat-form-field class="width-100">
                    <mat-label>Оберіть дату</mat-label>
                    <input name="date" matInput [matDatepicker]="datePicker" [(ngModel)]="model.date">
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <mat-form-field class="width-100">
                <mat-label>Деталі запиту</mat-label>
                <textarea matInput name="orderDetails" rows="10" [(ngModel)]="model.details"
                    [required]="true"></textarea>
            </mat-form-field>

            <mat-form-field class="width-100">
                <mat-label>Файл</mat-label>
                <input name="fileName" matInput [(ngModel)]="model.fileName" readonly />
                <button type="button" mat-icon-button matSuffix aria-label="Очистити" (click)="clear()"
                    *ngIf="model.fileName">
                    <mat-icon>close</mat-icon>
                </button>
                <button type="button" mat-icon-button matSuffix aria-label="Завантажити" (click)="fileInput.click()"
                    *ngIf="!model.fileName">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <input #fileInput type="file" (click)="fileInput.value = ''" value=""
                    (change)="filesPicked(fileInput.files)" class="hidden-input" />
            </mat-form-field>

        </div>
    </div>
    <div mat-dialog-actions>
        <div>
            <button type="button" mat-button [mat-dialog-close]="{dialogResult : dialogResult.cancel}"
                cdkFocusInitial>Скасувати запит</button>
        </div>
        <div>
            <button type="submit" mat-button color="primary" [disabled]="form.form.invalid">Надіслати запит</button>
        </div>
    </div>
</form>