<div mat-dialog-title>
    <h3>Unhandled Error</h3>
    <button type="button" class="close-button" mat-icon-button color="primary" aria-label="Close"
        [mat-dialog-close]="{ dialogResult : dialogResult.cancel}">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content class="unhandled-error-details">
    <div><b>Status:</b> <span>{{data?.error?.status}}</span>({{data?.error?.statusText}})</div>
    <div><b>Url:</b> <span>{{data?.error?.url}}</span></div>
    <div><b>Message:</b> <span>{{data?.error?.message}}</span></div>
    <div [hidden]="!showDetails">
        <pre>{{data|json}}</pre>
    </div>
</div>
<div mat-dialog-actions>
    <div> <button type="button" mat-button (click)="showDetails = !showDetails">
            <span [hidden]="!showDetails">Сховати</span>
            <span [hidden]="showDetails">Показати</span>&nbsp;
            <span>деталі</span></button>
    </div>
    <div> <button type="button" mat-button [mat-dialog-close]="{ dialogResult : dialogResult.cancel}" cdkFocusInitial>
            Закрити
        </button>
    </div>
</div>