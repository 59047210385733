<div class="table-wrapper container">

  <div class="page-header">
    <div class="page-title">
      <h2>Договори</h2>
    </div>
    <div class="page-actions">
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort class="width-100 mat-elevation-z8">

    <ng-container matColumnDef="name">
      <!-- mat-sort-header -->
      <th mat-header-cell *matHeaderCellDef>Юридична особа</th>
      <td mat-cell *matCellDef="let item">{{item.name}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <!-- mat-sort-header -->
      <th mat-header-cell *matHeaderCellDef>Початкова дата</th>
      <td mat-cell *matCellDef="let item">{{item.date|date:'dd.MM.yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dateEnd">
      <th mat-header-cell *matHeaderCellDef>Кінцева дата</th>
      <td mat-cell *matCellDef="let item">{{(item.dateEnd|date:'dd.MM.yyyy') || '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="contractor">
      <th mat-header-cell *matHeaderCellDef>Контрагент</th>
      <td mat-cell *matCellDef="let item">{{item.contractor}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Тип договору</th>
      <td mat-cell *matCellDef="let item">{{item.type}} </td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>Номер</th>
      <td mat-cell *matCellDef="let item">{{item.number}} </td>
    </ng-container>


    <ng-container matColumnDef="sugarQuantityFrom1Tone">
      <th mat-header-cell *matHeaderCellDef>База цукру, кг, за 1 т. буряків</th>
      <td mat-cell *matCellDef="let item">{{item.sugarQuantityFrom1Tone | number : '1.2-2'}} </td>
    </ng-container>


    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef>Договір</th>
      <td mat-cell *matCellDef="let item">
        <ng-container *ngIf="item?.images?.length <= 1">
          <button type="button" mat-icon-button matTooltip="Відкрити скан-копію договору"
            [disabled]="!item?.images?.length" (click)="loadImage(item,0)">
            <mat-icon>file_copy</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="item?.images?.length > 1">
          <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>file_copy</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item *ngFor="let image of item.images; let idx = index"
              (click)="loadImage(item,idx)">
              <span>{{item.image[idx]}}</span>
            </button>
          </mat-menu>

        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="groupHeader">
      <th mat-header-cell *matHeaderCellDef>Юридична особа</th>
      <td [attr.colspan]="displayedColumns.length" mat-cell *matCellDef="let company">
        <button type="button" mat-icon-button (click)="changeVisibility(company)">
          <mat-icon>{{company.showContracts ? 'chevron_right':'expand_more'}}</mat-icon>
        </button>
        <span>
          {{company.name}}
        </span>
        <span class="company-info">
          &nbsp;Земельний банк: <span title="Земельний банк, га">{{company.totalArea || 0}} га.</span>
          &nbsp;Засіяно буряком:<span title="Засіяно буряком, га">{{company.beetArea || 0}} га.</span>
          &nbsp;Засіяно буряком:<span title="Засіяно буряком, %">{{company.beetAreaPercentage || 0}}%</span>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="contract" *matRowDef="let row; columns: displayedColumns; when: isContract"
      [hidden]="!row.isVisible"></tr>
    <tr mat-row class="company" *matRowDef="let row; columns: ['groupHeader']; when: isCompany"></tr>
  </table>
</div>