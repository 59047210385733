<div class="login-wrapper height-100">
    <div class="container centered-wrapper">
      <h3>
<!--        <img alt="УКРПРОМІНВЕСТ АГРО" src="/assets/images/logo-ua.png" height="61">-->
        <img alt="УКРПРОМІНВЕСТ АГРО" src="/assets/images/logo-ua-2.png" height="100">
      </h3>
<!--      <p class="info">Для авторизації в системі введіть ваш номер телефону</p>-->
      <login-form (onSuccess)="onUserLogedIn($event)"></login-form>
    </div>
</div>
