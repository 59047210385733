import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppCommonModule } from './app-common/app-common.module';
import { PageNotFoundComponent } from './app-common/components/page-not-found/page-not-found.component';
import { LandingComponent } from './components/landing/landing.component';
import { UserGuard } from './guards/user.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboards', pathMatch: 'full' },
  { path: 'tg/:token/:page', pathMatch: 'full', component: LandingComponent },
  { path: 'contragents', pathMatch: 'prefix', loadChildren: () => import('./contragents/contragents.module').then(m => m.ContragentsModule), data: { title: 'Контрагент', permission: { name: 'view', subject: 'contragents' } }, canActivate: [UserGuard] },
  { path: 'organization', pathMatch: 'prefix', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule), data: { title: 'Організація', permission: { name: 'view', subject: 'organization' } }, canActivate: [UserGuard] },
  { path: 'maps', pathMatch: 'full', loadChildren: () => import('./vehicle-monitor/vehicle-monitor.module').then(m => m.VehicleMonitorModule), data: { title: 'Збір та постачання буряків', permission: { name: 'view', subject: 'maps' } }, canActivate: [UserGuard] },
  { path: 'services', pathMatch: 'prefix', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule), data: { title: 'Контрагент', permission: { name: 'view', subject: 'contacts' } }, canActivate: [UserGuard] },
  { path: 'dashboards', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [UserGuard] },
  { path: '404', component: PageNotFoundComponent, data: { title: 'Ресурс не знайдено' } },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [
    AppCommonModule,
    RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'corrected' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
