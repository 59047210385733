import { Inject, Injectable } from '@angular/core';
import { AuthenticationService, User, OTCResponse, OTCResponseExt, LoginResponse, MainContractor, TokenLoginResult } from '@upi/data-services';
import { UserService } from '../app-common/services/user.service';
import { AppConfig } from '../app-common/types/app-config';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { CommonDataService } from './common-data.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateDataService extends CommonDataService {

  constructor(
    logger: NGXLogger,
    @Inject(APP_CONFIG) appConfig: AppConfig,
    protected userService: UserService,
    private authenticationService: AuthenticationService,
  ) {
    super(logger, appConfig, userService);
    this.loggerName = 'AuthenticateDataService';
  }

  // getUrl() {
  //   return 'test2';// `${this.appConfigService.configuration.dataServer}?`;
  // }

  oneTimeCodeRequest(phone: string): Observable<OTCResponseExt> {

    this.logger.trace(this.loggerName, 'requestCode', phone);

    return this.authenticationService.oneTimeCodeRequest(this.dataSeverUrl, phone.replace(/-/g, ''));

  }

  oneTimeCodeRequest2(phone: string, contractor: string): Observable<OTCResponse> {

    this.logger.trace(this.loggerName, 'requestCode', phone, contractor);

    return this.authenticationService.oneTimeCodeRequest2(this.dataSeverUrl, phone.replace(/-/g, ''), contractor);

  }

  loginUser(): Observable<LoginResponse> {

    this.logger.trace(this.loggerName, 'login');

    return this.authenticationService.login(this.dataSeverUrl);

  }

  authenticate(login: string, password: string, isSuperUser: boolean, otcResponse: OTCResponse, contractor?: string): Observable<User> {

    this.logger.trace(this.loggerName, 'authenticate', login, password);

    return this.authenticationService.authenticate(this.dataSeverUrl, login, password, isSuperUser, contractor || '', otcResponse);

  }

  getContragentsList(login: string): Observable<MainContractor[]> {

    this.logger.trace(this.loggerName, 'authenticate', login);

    return this.authenticationService.getContragentsList(this.dataSeverUrl, login);

  }

  getAuthorizationData(token: string) : Observable<TokenLoginResult> {

    this.logger.trace(this.loggerName, 'getAuthorizationData', token);

    return this.authenticationService.getAuthorizationData(this.dataSeverUrl, token);

  }

}
