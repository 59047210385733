import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NGXLogger } from 'ngx-logger';
import { UnhandledErrorDetailsComponent } from "../components/unhandled-error-details/unhandled-error-details.component";

@Injectable()
export class GlobalErrorHandler {

    private loggerName = 'GlobalErrorHandler';

    constructor(
        private logger: NGXLogger,
        private dialog: MatDialog,
        private ngZone: NgZone,
    ) { }

    handleError(error: any): void {

        try {

            this.logger.error(this.loggerName, error);

            if ((error instanceof HttpErrorResponse) || (error.rejection && error.rejection instanceof HttpErrorResponse)) {

                this.ngZone.run(() => {
                    this.dialog.open(UnhandledErrorDetailsComponent, {
                        data: {
                            title: 'Помилка',
                            error: error,
                            message: error.message,
                            showCloseButton: true,
                        }
                    });
                });

                return;

            }

            if (!this.dialog?.openDialogs?.length) {

                this.ngZone.run(() => {
                    this.dialog.open(UnhandledErrorDetailsComponent, {
                        data: {
                            title: 'Помилка',
                            error: !!error.rejection ? error.rejection : error, 
                            message: error.message,
                            showCloseButton: true,
                        }
                    });
                });

                return;
            }

        }
        catch (ex) {
            console.error(ex);
        }
    }

}
