<div class="container">
  <div class="page-header">
    <div class="page-title">
      <h2>Структура</h2>
    </div>
    <div>
    </div>
  </div>
  <div class="chart-wrapper">
    <div #chartContainer id="chartContainer" class="chart-container"></div>
  </div>
</div>
