import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '../../../app-common/types/app-config';
import { ComponentBase } from '../../../app-common/types/component-base';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-image-galery-preview-dialog',
  templateUrl: './image-galery-preview-dialog.component.html',
  styleUrls: ['./image-galery-preview-dialog.component.scss']
})
export class ImageGaleryPreviewDialogComponent extends ComponentBase implements OnInit {

  readonly picturesUrls : string[];

  error : any = null;
  
  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(logger, appConfig, 'ImageGaleryPreviewDialogComponent');
    this.picturesUrls = data.images;
  }

  ngOnInit(): void {

  }

}
