<div class="contact-info-wrapper">
    <img [src]="'/assets/contacts/photo/' + data.photo" />
    <div class="text-info">
        <div *ngIf="data.position">{{data.position}}</div>
        <div *ngIf="data.organization">{{data.organization}}</div>
        <div *ngIf="data.name">{{data.name}}</div>
        <div *ngIf="data?.contacts?.email">{{data.contacts.email}}</div>
        <div *ngIf="data?.contacts?.phone">{{data.contacts.phone}}</div>
    </div>
</div>
