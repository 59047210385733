import { Component, OnInit } from '@angular/core';
import { DialogResultEnum } from '@upi/common';
import { MainContractor } from '@upi/data-services';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../../../app-common/services/user.service';
import { AppAuthenticationService } from '../../../authentication/services/authentication.service';

@Component({
  selector: 'app-contragent-selector-dialog',
  templateUrl: './contragent-selector-dialog.component.html',
  styleUrls: ['./contragent-selector-dialog.component.scss']
})
export class ContragentSelectorDialogComponent implements OnInit {

  private loggerName = 'ContragentSelectorDialogComponent';

  dialogResult = DialogResultEnum;

  maincontractors?: MainContractor[];
  contractorGuid : string = '';

  constructor(
    private logger : NGXLogger,
    private authenticationService: AppAuthenticationService,
    private userService : UserService
  ) { }

  ngOnInit(): void {
    this.loadData(this.userService.user.userName || '');
  }

  loadData(userName : string) {
    this.authenticationService.getContragentsList(userName).subscribe((contractors)=>{
      this.maincontractors = contractors;
      this.contractorGuid = this.userService.user?.contractor || '';
    })
  }

}
