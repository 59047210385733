import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ForecastCalculationTotal, ForecastCalculationDetail, ForecastData, Contragent, Company } from '@upi/data-services';
import { BarterDataService } from '../../../data-services/barter-data.service';
import { NGXLogger } from 'ngx-logger';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { AppConfig } from '../../../app-common/types/app-config';
import { ComponentBase } from '../../../app-common/types/component-base';
import { GeneralDataService } from '../../../data-services/general-data.service';
import { forkJoin } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { UserService } from 'src/app/app-common/services/user.service';
import { LocalDataStorageService } from 'src/app/app-common/services/local-data-storage.service';


export class BarterListFilter {
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  contragent!: string;
}

@Component({
  selector: 'app-barter-list',
  templateUrl: './barter-list.component.html',
  styleUrls: ['./barter-list.component.scss']
})
export class BarterListComponent extends ComponentBase implements OnInit, AfterViewInit {

  // DropDowns
  contragents: string[] = [];

  minDate: Date = new Date(2000, 1, 1);
  filter!: BarterListFilter;
  contragent!: Contragent;
  data!: ForecastData;
  detailedMode = false;



  dataSourceTotal = new MatTableDataSource<ForecastCalculationTotal>();
  displayedTotalHeaderColumns: string[] = ['organization', 'physicalWeight', 'sugarCustomers'];
  displayedTotalColumns: string[] = ['organization', 'physicalWeight', 'sugarCustomers'];
  contragentTotalPhysicalWeight!: number;
  contragentTotalSugarCustomers!: number;

  dataSourceDetails = new MatTableDataSource<ForecastCalculationDetail>();
  displayedDetailedHeaderColumnsRow1: string[] = ['header-row-first', 'header-row-second', 'header-row-third', 'header-row-fourth', 'header-row-second-group', 'header-row-third-group']
  displayedDetailedHeaderColumnsRow2: string[] = ['physicalWeight', 'sugarContent', 'contamination', 'sugar1TonBeets', 'conversionRate', 'sugarCustomers']
  displayedDetailedColumns: string[] = ['date', 'numberTTN', 'organization', 'field', 'physicalWeight', 'sugarContent', 'contamination', 'sugar1TonBeets', 'conversionRate', 'sugarCustomers'];

  @ViewChild('sortTotal') sortTotal!: MatSort;
  @ViewChild('sortDetails') sortDetails!: MatSort;

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private barterDataService: BarterDataService,
    private generalDataService: GeneralDataService,
    private userService : UserService,
    private localDataStorageService : LocalDataStorageService,

  ) {
    super(logger, appConfig, 'BarterListComponent');
    this.filter = new BarterListFilter();
    const date = new Date();
    this.filter.dateFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
    this.filter.dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  }

  ngOnInit(): void {
    this.logger.trace(this.loggerName, 'ngOnInit');
    this.loadContragents();
    this.loadData(this.filter);
  }

  ngAfterViewInit(): void {
  }

  loadContragents() {
    this.generalDataService.getOrganizationChart(this.userService.contractorGuid).subscribe((contragent: Contragent) => {
      this.contragents = [];
      contragent.subordinateCompanies.forEach((el: Company) => {
        this.contragents.push(el.name);
      });;
    })
  }

  loadData(filter: BarterListFilter) {

    this.logger.trace(this.loggerName, "loadData");

    forkJoin([
      this.barterDataService.getList(this.localDataStorageService.productionYear, this.userService.contractorGuid),
      this.generalDataService.getOrganizationChart(this.userService.contractorGuid)
    ])
      .subscribe((data: any) => {

        const forecastData: ForecastData = data[0];
        const contragent: Contragent = data[1];

        this.contragent = contragent;

        this.data = forecastData;

        this.contragentTotalPhysicalWeight = 0;
        this.contragentTotalSugarCustomers = 0;
        forecastData.total.forEach((el: ForecastCalculationTotal) => {
          this.contragentTotalPhysicalWeight += +el.physicalWeight;
          this.contragentTotalSugarCustomers += +el.sugarCustomers;
        });

        const contragentRow = new ForecastCalculationTotal(null);
        contragentRow.contractor = contragent.name;

        contragentRow.physicalWeight = this.contragentTotalPhysicalWeight;
        contragentRow.sugarCustomers = this.contragentTotalSugarCustomers;
        contragentRow.isContragent = true;

        //this.data.total.sort((a: ForecastCalculationTotal, b: ForecastCalculationTotal) => (a?.contractor > b?.contractor) ? -1 : 1);
        this.data.total.unshift(contragentRow);
        this.updateDataTableTotal(this.data.total);

        // this.data.forecastcalculation.sort((a: ForecastCalculationDetail, b: ForecastCalculationDetail) => (a.date.getTime() > b.date.getTime() ? 1 : -1));
        this.updateDataTableDetails(this.data.forecastcalculation);

        this.dataSourceDetails.filter = JSON.stringify(filter);

        this.calculateSum();
      });
  }

  private sortingDataAccessor(item: ForecastCalculationTotal, property: string) {

    return (item.isContragent ? 0 : 1) + item[property];

  }


  updateDataTableTotal(data: ForecastCalculationTotal[]) {


    this.sortTotal.active = 'contractor';
    this.sortTotal.direction = 'asc';

    data = data.sort()

    this.dataSourceTotal = new MatTableDataSource(data);
    this.dataSourceTotal.sortingDataAccessor = (item: ForecastCalculationTotal, property: string): any => this.sortingDataAccessor(item, property);
    this.dataSourceTotal.sort = this.sortTotal;

  }

  updateDataTableDetails(data: ForecastCalculationDetail[]) {

    this.sortDetails.active = 'date';
    this.sortDetails.direction = 'asc';

    this.dataSourceDetails = new MatTableDataSource(data);
    this.dataSourceDetails.filterPredicate = (data, filter) => this.filterData(data, filter);
    this.dataSourceDetails.sort = this.sortDetails;

  }

  tableModeToggle() {
    this.calculateSum();
  }

  private filterData(data: ForecastCalculationDetail, filter: string): boolean {

    let result = true;

    let _filter: BarterListFilter = JSON.parse(filter);

    if (result && _filter.contragent) {
      result = result && (data.contractor === _filter.contragent);
    }

    if (result && (_filter.dateFrom || _filter.dateTo)) {
      if (_filter.dateFrom) {
        result = result && data.date.getTime() >= new Date(_filter.dateFrom).getTime();
      }
      if (result && _filter.dateTo) {
        result = result && data.date.getTime() <= new Date(_filter.dateTo).getTime();
      }

    }

    return result;
  }

  applyFilter(filter: BarterListFilter) {
    this.dataSourceDetails.filter = JSON.stringify(filter);
    this.calculateSum();
  }

  calculateSum() {

    this.logger.trace(this.loggerName, 'calculateSum');

    this.contragentTotalPhysicalWeight = 0;
    this.contragentTotalSugarCustomers = 0;

    this.dataSourceDetails.filteredData.forEach((el: ForecastCalculationDetail) => {
      this.contragentTotalPhysicalWeight += el.physicalWeight;
      this.contragentTotalSugarCustomers += el.sugarCustomers;
    });

  }

  isContragent(index: number, item: ForecastCalculationTotal) {
    return item['isContragent'] == true;
  }

  changeVisibility(item: ForecastCalculationTotal) {

    this.logger.trace(this.loggerName, 'changeVisibility', item);

    item.showDetails = !item.showDetails;
    this.dataSourceTotal.data.forEach(_item => {
      _item.isVisible = !_item.isVisible;
    });

    this.dataSourceTotal.data = this.dataSourceTotal.data;
  }

  dateFromChanged(e: any, filter: BarterListFilter) {

    this.filter.dateFrom = e.value;

    if (e.value && filter?.dateTo?.getTime() < e.value.getTime()) {
      let date = new Date(e.value);
      date.setDate(date.getDate() + 7);
      this.filter.dateTo = new Date(date);
      this.filter.dateTo.setHours(23);
      this.filter.dateTo.setMinutes(59);
      this.filter.dateTo.setSeconds(59);
      }

    this.loadData(filter);

  }

  dateToChanged(e: any, filter: BarterListFilter) {

    this.filter.dateTo = e.value;
    this.filter.dateTo.setHours(23);
    this.filter.dateTo.setMinutes(59);
    this.filter.dateTo.setSeconds(59);

    if (e.value && e.value.getTime() < filter?.dateFrom?.getTime()) {
      let date = new Date(e.value);
      date.setDate(date.getDate() - 7);
      this.filter.dateFrom = new Date(date);
    }

    this.loadData(filter);

  }

}
