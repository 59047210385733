import { Component, ElementRef, Input, OnInit, AfterViewInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { GeneralDataService } from "../../../data-services/general-data.service";
import { Contragent, Company, ContactPerson, Phone } from '@upi/data-services';
// import * as d3 from "d3";
// import * as TreeChart from 'd3-org-chart';
import { OrgChart } from 'd3-org-chart';
import { JsonPipe } from '@angular/common';
import { UserService } from 'src/app/app-common/services/user.service';


@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements OnInit, AfterViewInit {

  private loggerName = 'OrgChartComponent';

  contragent!: Contragent;
  viewMode!: boolean;

  @ViewChild("chartContainer") chartContainer!: ElementRef;
  @Input() data!: any[];
  chart!: any;


  @ViewChild('defaultTabButtons') private defaultTabButtonsTpl!: TemplateRef<any>;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.chart) {

      const w = (event.target.innerWidth);

      if (w < 320) {

      } else if (w < 640) {
        this.chart.initialZoom(0.7);
      }

      this.chart.fit();

    }

  }

  constructor(
    private logger: NGXLogger,
    private generalDataService: GeneralDataService,
    private userService : UserService,
  ) {
  }

  ngAfterViewInit(): void {

    this.logger.trace(this.loggerName, "ngAfterViewInit");

    if (!this.chart) {
      this.chart = new OrgChart();
    }

    this.loadData();

  }

  updateChart() {

    this.logger.trace(this.loggerName, "updateChart");

    if (!this.data) {
      return;
    }
    if (!this.chart) {
      return;
    }

    const pipe = new JsonPipe();

    this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.data)
      //.svgWidth(800)
      .svgHeight(600)
      .compact(false)
      .layout('top')
      .initialZoom(0.50)
      .nodeHeight(d =>
        // could be depended on tyoe of node
        d.data.height
      )
      .nodeWidth(d => {
        // could be depended on type of node
        return d.data.width;
      })
      .childrenMargin(d => 160)
      .siblingsMargin(d => 200)
      //.compactMarginBetween(d => 165)
      //.compactMarginPair(d => 300)
      //.neightbourMargin((a, b) => 250)
      .nodeContent((d, index, arr, state) => {
        this.logger.trace(this.loggerName, "nodeContent" /*, d, index, arr, state*/);
        return d.data.template;
      })
      .onNodeClick((d) => {
        this.logger.trace(this.loggerName, "onNodeClick", d);
      })
      .render()
      ;
  }

  ngOnInit(): void {
    this.logger.trace(this.loggerName, 'ngOnInit');
  }

  loadData() {

    this.logger.trace(this.loggerName, "loadData");

    this.generalDataService.getOrganizationChart(this.userService.contractorGuid).subscribe((contragent: Contragent) => {
      this.contragent = contragent;
      this.data = [];
      this.buildTree(this.data, contragent);
      this.updateChart();
    });

  }

  buildTree(data: any[], contragent: Contragent) {
    this.data.push(this.getContragentTreeNode(contragent));
    contragent.subordinateCompanies.forEach((company: Company) => {
      this.data.push(this.getCompanyTreeNode(contragent, company));
    });
  }

  formatPhone(value: string) {
    if (!value || value.length != 12) {
      return value;
    }

    return `${value.substr(0, 2)}(${value.substr(2, 3)})${value.substr(5, 3)}-${value.substr(8, 2)}-${value.substr(10, 2)}`
  }

  getPhoneHtml(phone: Phone) {
    return `
      <div class="contact-person-phone">
        <span class="contact-person-phone-caption"></span>
        <a href="tel:+3${phone.phone}">${this.formatPhone(phone.phone)}</a>
      </div>`;
  }

  getPhonesHtml(contactPerson: ContactPerson) {
    let _str = "";

    contactPerson.phones.forEach((phone: Phone) => {
      _str = _str + this.getPhoneHtml(phone);
    });

    return _str;
  }

  getContactPersonHtml(contactPerson: ContactPerson) {
    return `
      <div class="contact-person">
        <div class="contact-person-caption"></div>
        <span class="contact-person-name">${contactPerson.name}</span>
      </div>
      <div class="contact-person-phones">
        <div class="contact-person-phones-caption"></div>
        ${this.getPhonesHtml(contactPerson)}
      </div>
    `;
  }

  getContragentHtml(contragent: Contragent) {

    let contactpersonsHtml = '';

    if (contragent.contactPersons?.length > 0) {

      let _contactpersonsHtml = '';

      contragent.contactPersons
        .slice(0, this.getMaxNumberOfContragents(contragent.contactPersons.length))
        .forEach((contactPerson: ContactPerson) => {
          _contactpersonsHtml = _contactpersonsHtml + this.getContactPersonHtml(contactPerson);
        }
        );

      contactpersonsHtml = `
        <div class="contragent-contact-persons-wrapper">
          <div class="contragent-contact-persons-caption"></div>
          <div class="contragent-contact-persons-list">
            ${_contactpersonsHtml}
          </div>
        </div>
      `;

    }

    const yearSuffix = contragent.cooperationPeriod == 1 ? 'рік' : 'років';

    return `
      <div class="contragent-wrapper">
        <div class="contragent-image"><img src="/assets/images/orgchart-contragent.svg" /></div>
        <div class="contragent">
          <span class="contragent-caption"></span>
          <span class="contragent-name">${contragent.name}<span>
        </div>
        ${contactpersonsHtml}        
        <div class="contragent-status">
          <div class="contragent-status-period">
            <span>Ви наш партнер вже:</span>
            <b>${contragent.cooperationPeriod || 0}</b>&nbsp;${yearSuffix}
          </div>
          <div class="contragent-status-title">
            <span>Ваш статус: </span>
            <b>${contragent.supplierStatus ||''}</b>
          </div>
        </div>
      </div>
    `;

  }


  getComanyHtml(contragent: Contragent, company: Company) {

    let contactpersonsHtml = '';

    if (company.contactPersons?.length > 0) {

      let _contactpersonsHtml = '';

      company.contactPersons.forEach((contactPerson: ContactPerson) => {
        _contactpersonsHtml = _contactpersonsHtml + this.getContactPersonHtml(contactPerson);
      });

      contactpersonsHtml = `
        <div class="company-contact-persons-wrapper">
          
          <div class="company-contact-persons-caption"></div>
          <div class="company-contact-persons-list">
          ${_contactpersonsHtml}
          </div>
        </div>
      `;

    }

    return `
      <div class="company-wrapper">
      <div class="company-image"><img src="/assets/images/orgchart-company.svg" /></div>
      <div class="company">
          <span class="company-caption"></span>
          <span class="company-name">${company.name}<span>
        </div>
        <div class="company-info">
          <div class="company-info-area">
            <span class="company-info-area-title">Земельний банк:</span>
            <span  class="company-info-area-value">${company.totalArea || 0} Га.</span>
          </div>
          <div class="company-info-area">
            <span class="company-info-area-title">Засіяно буряком:</span>
            <span  class="company-info-area-value">${company.beetArea || 0} Га.</span>
          </div>
          <div class="company-info-area">
            <span class="company-info-area-title">Засіяно буряком:</span>
            <span  class="company-info-area-value">${company.beetAreaPercentage || 0} %</span>
          </div>
          <!--
          <div class="company-info-supplierStatus">
            <span class="company-info-title">Статус:</span>
            <span>
              <span title="Статус контрагента">${company.supplierStatus || '-'}</span>&nbsp;
              <span title="Термін співпраці з контрагентом">(${company.cooperationPeriod || 0} р.)</span>
            </span>
          </div>
          <div class="company-info-area">
            <span class="company-info-title">Площа:</span>
            <span>
              <span title="Загальна площа, га">${company.totalArea || 0} га.</span>/
              <span title="Площа під цукровим буряком, га">${company.beetArea || 0} га.</span>/
              <span title="Відсоток площі під цукровим буряком, %">${company.beetAreaPercentage || 0} %</span>
              <span>
          </div>
          -->
        </div>
        ${contactpersonsHtml}
      </div>
    `;
  }

  getCompanyTreeNode(contragent: Contragent, company: Company) {

    return {
      "type": 'company',
      "nodeId": company.guid,
      "parentNodeId": contragent.guid,
      "width": 550,
      "height": company.contactPersons?.length > 0 ? ((company.contactPersons.length + 1) * 100 + 210) : 150,
      "name": company.name,
      "borderWidth": 1,
      "borderRadius": 5,
      "borderColor": {
        "red": 15,
        "green": 140,
        "blue": 121,
        "alpha": 1
      },
      "backgroundColor": {
        "red": 15,
        "green": 140,
        "blue": 121,
        "alpha": 1
      },
      "template": this.getComanyHtml(contragent, company),
      "nodeImage": {
        "url": "assets/images/orgchart-company.svg",
        "width": 80,
        "height": 80,
        "centerTopDistance": 0,
        "centerLeftDistance": 0,
        "cornerShape": "CIRCLE",
        "shadow": false,
        "borderWidth": 0,
        "borderColor": "none"
      },
      "expnded": true,
    };

  }

  getMaxNumberOfContragents(length: number): number {
    return (length > 5) ? 5 : length;
  }

  getContragentTreeNode(contragent: Contragent) {

    const maxNumberOfContragents = this.getMaxNumberOfContragents(contragent.contactPersons.length);

    return {
      "nodeId": contragent.guid,
      "parentNodeId": null,
      "width": 700,
      "height": maxNumberOfContragents > 0 ? (maxNumberOfContragents * 100) + 147 : 150,
      "borderWidth": 1,
      "borderRadius": 5,
      "borderColor": {
        "red": 15,
        "green": 140,
        "blue": 121,
        "alpha": 1
      },
      "backgroundColor": {
        "red": 15,
        "green": 140,
        "blue": 121,
        "alpha": 1
      }, "template": this.getContragentHtml(contragent),
      "nodeImage": {
        "url": "assets/images/orgchart-contragent.svg",
        "width": 100,
        "height": 100,
        "centerTopDistance": 0,
        "centerLeftDistance": 0,
        "cornerShape": "CIRCLE",
        "shadow": false,
        "borderWidth": 0,
        "borderColor": "none"
      },
      "expnded": true,
    };

  }

}
