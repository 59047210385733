import { Component, Inject, OnInit } from '@angular/core';
import {PresentationItem } from '@upi/data-services';
import { AppConfig } from '../../../app-common/types/app-config';
import { ComponentBase } from '../../../app-common/types/component-base';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { ContactsService } from '../../../data-services/contacts.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent  extends ComponentBase implements OnInit {

  presentations!: PresentationItem[];

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private contactsService: ContactsService,
  ) {
    super(logger, appConfig, 'ContactsComponent');
  }

  ngOnInit(): void {
    this.load();
  }

  load() {

    this.logger.trace(this.loggerName, 'load');
    
    this.contactsService.getPresentationsList().subscribe((data) => {
      this.presentations = data;
    });

  }

}

