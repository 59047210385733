import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from '@upi/common';
import { LocalDataStorageService } from '../app-common/services/local-data-storage.service';
import { AppAuthenticationService } from '../authentication/services/authentication.service';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, finalize, map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../authentication/components/login-dialog/login-dialog.component';
import { AppConfig } from '../app-common/types/app-config';
import { UserService } from '../app-common/services/user.service';
import { APP_CONFIG } from '../app-common/types/injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

  private loggerName = 'RequestInterceptorServiceService';
  private q = 0; // number of opened requests
  private waitLoginSubject: Subject<any> = new Subject<any>();

  constructor(
    private logger: NGXLogger,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private router: Router,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private localDataStorageService: LocalDataStorageService,
    private dialog: MatDialog
  ) {

  }

  private checkFCCabinet(url : string) : boolean {
    let res = false; 
    res = res || url.indexOf('onetimecoderequest=true') !== -1;
    res = res || url.indexOf('getauthorizationdata=true') !== -1;
    return res;
  }

  private setAuthorization(request: HttpRequest<any>): HttpRequest<any> {

    const currentUser = this.userService.user;

    if (AppConfig.isExternalResource(request.url, this.appConfig.externalResources.contracts)) {
      request = request.clone({
        setHeaders: { Authorization: 'Basic ' + window.btoa('dogovora:Pk01sCexNByjmiGS') }
      });
    } else if (AppConfig.isExternalResource(request.url, this.appConfig.externalResources.images)) {
      request = request.clone({
        setHeaders: { Authorization: 'Basic ' + window.btoa('driverph:E6fX2j9Kx5') }
      });
    } else {
      if (this.checkFCCabinet(request.url)) {
        request = request.clone({
          setHeaders: { Authorization: 'Basic ' + window.btoa('fcabinet:f21cabinet') }
        });
      } else if (!!currentUser?.authData) {
        request = request.clone({
          setHeaders: { Authorization: `Basic ${currentUser.authData}` }
        });
      }
    }

    return request;

  }

  private showLoader(): void {
    this.spinnerService.show();
  }

  private hideLoader(): void {
    this.spinnerService.hide();
  }

  onEnd(): void {
    this.q = 0;
    this.hideLoader();
  }

  hide() {
    this.q--;
    if (this.q <= 0) {
      this.onEnd();
    }
  }

  private openLoginModal(): Observable<any> {

    this.logger.trace(this.loggerName, "openLoginModal()");

    if (this.router.url === '/') {
      this.router.navigate(['/login']);
      return of(null);
    }

    this.hide();

    if (this.router.url === '/login') {
      return of(null);
    }

    if (this.dialog?.openDialogs?.length > 0) {

      if (this.dialog.openDialogs.findIndex((d) => d.componentInstance instanceof LoginDialogComponent) !== -1) {
        this.logger.trace(this.loggerName, "login dialog already opened");
        return of(false);
      }

    }

    return this.dialog.open(LoginDialogComponent, {
      width: '560px',
      disableClose: true
    }).afterClosed();

  }

  private handle400Error(error: any, caught: Observable<unknown>, request: HttpRequest<any>, next: HttpHandler) {

    this.logger.error(this.loggerName, 'Error 400:', error);

    this.hideLoader();

    return throwError(error);

  }

  private handle401Error(error: any, caught: Observable<unknown>, request: HttpRequest<any>, next: HttpHandler) {

    this.logger.error(this.loggerName, 'handle401Error:', error);

    this.hideLoader();

    this.localDataStorageService.removeUser();

    const url = this.router.url;

    const isNotLoginPage = (url.indexOf('login') == -1);

    if (isNotLoginPage) {

      return this.openLoginModal().pipe(
        concatMap((res) => {

          if (res === false) {
            return this.waitLoginSubject.pipe(
              switchMap((rs) => {
                return next.handle(this.setAuthorization(request));
              })
            )
          }

          this.waitLoginSubject.next(true);
          this.waitLoginSubject.complete();

          return next.handle(this.setAuthorization(request));

        })
      );

    }

    return throwError(error);

  }

  private handle500Error(error: any, caught: Observable<unknown>, request: HttpRequest<any>, next: HttpHandler) {

    this.logger.error(this.loggerName, 'handle500Error:', error);

    return throwError(error);

  }

  private handleDefaultError(error: any, caught: Observable<unknown>, request: HttpRequest<any>, next: HttpHandler) {

    this.logger.error(this.loggerName, 'handleDefaultError:', error);

    return throwError(error);

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // this.logger.trace(this.loggerName, "url: ", request.url, "; config:", this.appConfigService.configuration);

    this.showLoader();
    this.q++;

    return next
      .handle(this.setAuthorization(request))
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error, caught) => {

          this.logger.trace(this.loggerName, 'intercept. pipe. catchError:', error);

          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 400:
                return this.handle400Error(error, caught, request, next);
              case 401:
                return this.handle401Error(error, caught, request, next);
              case 500:
                return this.handle500Error(error, caught, request, next);
              case 503:
                return this.handle401Error(error, caught, request, next);
              default:
                return this.handleDefaultError(error, caught, request, next);
            }
          }

          return throwError(error);

        }),
        finalize(() => {
          this.q = this.q - 1;

          if (this.q <= 0) {
            this.q = 0;
            this.hideLoader();
          }
        })

      );

  }

}
