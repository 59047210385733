import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../app-common/types/app-config';
import { NGXLogger } from 'ngx-logger';
import { CommonDataService } from './common-data.service';
import { DataService, Contragent } from '@upi/data-services';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { UserService } from '../app-common/services/user.service';
import { ManagerInfo } from '@upi/data-services';
import { PricesItem } from '@upi/data-services';
import { ServiceItem } from '@upi/data-services';
import { AgroConsultingItem } from '@upi/data-services';

@Injectable({
  providedIn: 'root'
})
export class GeneralDataService extends CommonDataService {

  constructor(
    logger: NGXLogger,
    @Inject(APP_CONFIG) appConfig : AppConfig,
    protected userService : UserService,
    private dataService: DataService,
  ) {
    super(logger, appConfig, userService);
    this.loggerName = 'GeneralDataService';
  }

  getOrganizationChart(contractorGuid? : string): Observable<Contragent> {
    this.logger.trace(this.loggerName, "getOrganizationChart");
    return this.dataService.getOrganizationChart(this.dataSeverUrl, contractorGuid);
  }

  getExportSchedule(productionYear : number, contractorGuid? : string) {
    this.logger.trace(this.loggerName, "getExportSchedule", productionYear, contractorGuid);
    return this.dataService.getExportSchedule(this.dataSeverUrl, productionYear, contractorGuid);
  }

  orderService(serviceItem: ServiceItem, contractorGuid?: string) : Observable<boolean> {
    this.logger.trace(this.loggerName, "orderService", contractorGuid);
    return this.dataService.orderService(this.dataSeverUrl, serviceItem, contractorGuid);
  }

  getManagerData(contractorGuid? : string): Observable<ManagerInfo> {
    this.logger.trace(this.loggerName, "getManagerData", contractorGuid);
    return this.dataService.getManagerData(this.dataSeverUrl, contractorGuid);
  }

  getPrices(productionYear : number, contractorGuid? : string): Observable<PricesItem[]> {
    this.logger.trace(this.loggerName, "getPrices", productionYear, contractorGuid);
    return this.dataService.getPrices(this.dataSeverUrl, productionYear, contractorGuid);
  }

  getAgroConsultingItems(productionYear : number, contractorGuid? : string): Observable<AgroConsultingItem[]> {
    this.logger.trace(this.loggerName, "getAgroConsultingItems", productionYear, contractorGuid);
    return this.dataService.getAgroConsultingItems(this.dataSeverUrl, productionYear, contractorGuid);
  }

}
