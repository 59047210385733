import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { UserService } from '../../../app-common/services/user.service';

import { User } from '@upi/data-services';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  private loggerName = 'LoginComponent';

  constructor(
    private logger: NGXLogger,
    private userService: UserService,
    private router: Router,
    private dialogRef: MatDialogRef<LoginDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  onUserLogedIn(user: User) {
    this.logger.trace(this.loggerName, "onUserLogedIn", user);
    this.userService.setUserInfo(user);
    setTimeout(() => {
      this.router.navigateByUrl('/');
      this.dialogRef.close();
    }, 333);
  }

}
