import { Component, ElementRef, OnDestroy, OnInit, ViewChild, isDevMode, Output, EventEmitter } from '@angular/core';
import { LoginModel } from '../../../authentication/types/login-model';
import { User, OTCResponse, OTCResponseExt, MainContractor, LoginResponse } from '@upi/data-services';
import { NGXLogger } from 'ngx-logger';
import { AppAuthenticationService } from '../../services/authentication.service';
import { Subscription, timer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {

  private loggerName = 'LoginFormComponent';

  model: LoginModel = new LoginModel();
  otcResponseExt?: OTCResponseExt;
  otcResponse?: OTCResponse;
  maincontractors?: MainContractor[];
  error: any;
  formModeWaitForCode: boolean = false;
  timeLeft: number = 120;
  $interval: any;

  private phonePrefix = '38';

  private startCounter = 60;
  countDown?: Subscription;
  counter = this.startCounter;
  tick = 1000

  charPattern = /\d/;

  mode: boolean = false;
  step: number = 1;
  user?: User = undefined;

  @Output('onSuccess') readonly onSuccess = new EventEmitter<User>();

  constructor(
    private logger: NGXLogger,
    private authenticationService: AppAuthenticationService,
    private snackBar: MatSnackBar,
  ) {
    if (isDevMode()) {
      this.model.phone = '0677317081';
      this.model.password = '8945';
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  private getTimeDifference(date: Date): number {
    return Math.floor((date.getTime() - new Date().getTime()) / 1000 % 60);
  }

  private startTimer(endTime: Date) {

    this.logger.trace(this.loggerName, 'startTimer');

    this.counter = this.getTimeDifference(endTime);
    if (this.counter <= 0) {
      this.formModeWaitForCode = false;
      this.countDown?.unsubscribe();
    }

    this.countDown = timer(0, this.tick).subscribe(() => {
      --this.counter;
      if (this.counter <= 0) {
        this.formModeWaitForCode = false;
        this.countDown?.unsubscribe();
      }
    });

  }

  private getAuthData(login: string, password: string) {
    return window.btoa(`${login}:${password}`);
  }

  private getTimerDate() {
    const date: Date = new Date();
    // date.setMinutes(date.getMinutes() + this.appConfigService.configuration.otcRequestDelayMinutes || 1);
    return date;
  }

  requestCode(phone: string) {

    this.logger.trace(this.loggerName, 'requestCode', phone);

    this.mode = false;

    this.model.password = '';
    this.error = null;

    this.authenticationService.oneTimeCodeRequest(phone).subscribe(
      (data: OTCResponseExt) => {
        this.formModeWaitForCode = true;
        this.otcResponseExt = data;
        this.model.isSuperUser = data.superuser;
        this.startTimer(this.getTimerDate());
        this.snackBar.open('Код доступу відправлено на телефон', undefined, { duration: 3000 });
      },
      (error) => {
        this.error = error;
        this.formModeWaitForCode = false;
        this.otcResponseExt = undefined;
        if (error.status == 400) {
          this.startTimer(this.getTimerDate());
        }
      }
    );

    }

  private getPhoneNumber(phone: string): string {
    return this.phonePrefix + phone;
  }


  login(model: LoginModel, otcResponse?: OTCResponse) {

    this.logger.trace(this.loggerName, 'login', model, otcResponse);

    this.mode = true;

    if (!otcResponse) {
      otcResponse = new OTCResponse({ login: model.phone, name: '' });
    }

    this.authenticationService.doLogin(model.phone, model.password).subscribe(
      (lr: LoginResponse) => {

        this.logger.trace(this.loggerName, lr);
        const user = new User(undefined, this.getAuthData(this.getPhoneNumber(model.phone), model.password), undefined);
        user.isSuperUser = lr.superuser;
        user.userName = lr.login;
        user.firstName = lr.name;

        this.countDown?.unsubscribe();
        this.user = user;

        if (user.isSuperUser) {
          this.step = 2;
          this.authenticationService.getContragentsList(model.phone).subscribe((contractors)=>{
            this.maincontractors = contractors;
          })
        } else {
          this.onSuccess.emit(user);
        }

      },
      (error) => {
        this.error = error;
      }
    );
  }

  selectContragent(contractorGuid: string | undefined) {
    
    this.logger.trace(this.loggerName, 'selectContragent', contractorGuid);
    
    if (this.user) {
      this.user.contractor = contractorGuid;
    }

    this.onSuccess.emit(this.user);

  }

}
