<form #form="ngForm" novalidate>
  <p>Введіть ОТП пароль</p>
    <div class="pin-wrapper">
        <div class="pin" *ngFor="let p of pins; let i=index">
            <mat-form-field appearance="outline">
                <input #input [type]="hidden ? 'password' : 'text'" matInput [name]="'pin'+i"
                    autocomplete="one-time-code" maxlength="1" [required]="required" [pattern]="charPattern"
                    [(ngModel)]="p.value" (input)="onInput($event, i)" />
            </mat-form-field>
        </div>
    </div>
</form>
