import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../guards/user.guard';
import { BarterListComponent } from './components/barter-list/barter-list.component';
import { ConsignmentsListComponent } from './components/consignments-list/consignments-list.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContractsListComponent } from './components/contracts-list/contracts-list.component';
import { ContragentsComponent } from './components/contragents/contragents.component';
import { ExportScheduleListComponent } from './components/export-schedule-list/export-schedule-list.component';

const routes: Routes = [
  {
    path: 'contragents', pathMatch: 'prefix', component: ContragentsComponent, children: [
      { path: 'contracts', pathMatch: 'full', component: ContractsListComponent, data: { title: 'Договори' }, canActivate: [UserGuard] },
      { path: 'exportschedule', pathMatch: 'full', component: ExportScheduleListComponent, data: { title: 'Розклад вивезення' }, canActivate: [UserGuard] },
      { path: 'consignments', pathMatch: 'full', component: ConsignmentsListComponent, data: { title: 'Реєстр товаро-транспортних накладних та аналізів ' }, canActivate: [UserGuard] },
      { path: 'barter', pathMatch: 'full', component: BarterListComponent, data: { title: 'Прогноз взаємозаліків' }, canActivate: [UserGuard] },
      { path: 'contacts', pathMatch: 'full', component: ContactsComponent, data: { title: 'Сервіс для Вас' }, canActivate: [UserGuard] },
      { path: '', redirectTo: 'barter', pathMatch: 'full' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContragentsRoutingModule { }
