
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from '@angular/material/select';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from "./types/custom-date-adapter";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    imports: [
      MatFormFieldModule, MatInputModule,MatDialogModule,MatButtonModule,MatIconModule,MatTreeModule,MatSnackBarModule,
      MatTableModule, MatSortModule, MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule, MatMenuModule,
      MatSelectModule, MatTooltipModule
    ],
    exports: [
      MatFormFieldModule, MatInputModule,MatDialogModule,MatButtonModule,MatIconModule,MatTreeModule,MatSnackBarModule,
      MatTableModule, MatSortModule, MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule, MatMenuModule,
      MatSelectModule, MatTooltipModule
    ],
    providers: [
      { provide: MAT_DATE_LOCALE, useValue: 'uk' },
      { provide: DateAdapter, useClass: CustomDateAdapter }
    ]
  })
  export class AppMaterialModule { }
