import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../app-common/types/app-config';
import { NGXLogger } from 'ngx-logger';
import { BarterService, ForecastData, ForecastItem } from '@upi/data-services';
import { CommonDataService } from './common-data.service';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { UserService } from '../app-common/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class BarterDataService extends CommonDataService {

  constructor(
    logger: NGXLogger,
    @Inject(APP_CONFIG) appConfig: AppConfig,
    protected userService: UserService,
    private barterService: BarterService,
  ) {
    super(logger, appConfig, userService);
    this.loggerName = 'BarterDataService';
  }

  // Получить прогноз расчетов с давальцами.
  getList(productionYear: number, contractorGuid?: string): Observable<ForecastData> {

    this.logger.trace(this.loggerName, "getLocations");

    return this.barterService.getForecastCalculation(this.dataSeverUrl, productionYear, contractorGuid);

  }

  // Розрахінки з партнерами за період та сьогодні
  getDataShort(productionYear: number, contractorGuid?: string): Observable<ForecastItem[]> {

    this.logger.trace(this.loggerName);

    return this.barterService.getForecastDataShort(this.dataSeverUrl, productionYear, contractorGuid);

  }

}
