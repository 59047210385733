<div class="container">

  <div class="page-header">
    <div class="page-title">
      <h2>Прогноз взаємозаліків</h2>
    </div>
  </div>

  <div class="page-actions">
    <div>
      <!--  по контрагенту " + (contragent?.name||'') -->
      <mat-slide-toggle color="primary" [(ngModel)]="detailedMode" (ngModelChange)="tableModeToggle()">
        {{
        detailedMode
        ? "Детальні розрахункові показники по контрагенту " + (contragent.name)
        : "Розрахунковий показник взаємозаліків по контрагенту " + (contragent.name)
        }}
      </mat-slide-toggle>
    </div>
    <div>
    </div>
  </div>

  <div class="page-actions">
    <div *ngIf="detailedMode">
      <form #frmSearch="ngForm" id="frmSearch" (ngSubmit)="applyFilter(filter)" novalidate>
        <div>
          <!-- appearance="fill" -->

          <mat-form-field (click)="pickerFrom.open()">
            <mat-label>Початкова дата</mat-label>
            <input name="dateFrom" matInput [matDatepicker]="pickerFrom" [readonly]="true" [value]="filter.dateFrom"
              required [min]="minDate" (dateInput)="dateFromChanged($event, filter)">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field (click)="pickerTo.open()">
            <mat-label>Кінцева дата</mat-label>
            <input name="dateTo" matInput [matDatepicker]="pickerTo" [readonly]="true" [value]="filter.dateTo" required
              [min]="filter.dateFrom" (dateInput)="dateToChanged($event, filter)">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>

          <!-- <mat-form-field>
            <mat-label>Оберіть дати</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate name="dateFrom" #dateFrom="ngModel" [readonly]="true" [(ngModel)]="filter.dateFrom"
                required [min]="minDate">
              <input matEndDate name="dateTo" #dateTo="ngModel" [readonly]="true" [(ngModel)]="filter.dateTo" required
                [min]="dateFrom.value">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker (closed)="applyFilter(filter)"></mat-date-range-picker>
            <mat-error *ngIf="dateFrom.errors?.required">222</mat-error>
            <mat-error *ngIf="dateTo.errors?.required">333</mat-error>
          </mat-form-field> -->

          <!-- appearance="fill" -->
          <mat-form-field>
            <mat-label>Юридична особа</mat-label>
            <mat-select name="contragent" [(ngModel)]="filter.contragent" (ngModelChange)="applyFilter(filter)">
              <mat-option value=''>Всі</mat-option>
              <mat-option *ngFor="let contragent of contragents" [value]="contragent">
                {{contragent}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div>
      <div class="detailed-mode-info" *ngIf="detailedMode">
        <span class="detailed-mode-info-title">За період:</span>
        <mat-form-field>
          <mat-label>Надходження буряку</mat-label>
          <input type="text" matInput readonly [value]="contragentTotalPhysicalWeight  | number : '1.2-2'" />
          <span matSuffix>т.</span>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Цукор замовників</mat-label>
          <input type="text" matInput readonly [value]="contragentTotalSugarCustomers  | number : '1.3-3'" />
          <span matSuffix>т.</span>
        </mat-form-field>
      </div>

    </div>
  </div>

  <div class="table-wrapper">

    <div [hidden]="detailedMode">
      <table mat-table [dataSource]="dataSourceTotal" #sortTotal="matSort" matSort class="width-100 mat-elevation-z8">

        <ng-container matColumnDef="groupHeader">
          <th mat-header-cell *matHeaderCellDef>Юридична особа</th>
          <td mat-cell *matCellDef="let element" [class]="element.isContragent ? 'contragent' : 'contractor'">
            <button type="button" mat-icon-button (click)="changeVisibility(element)">
              <mat-icon>{{element.showContracts ? 'chevron_right':'expand_more'}}</mat-icon>
            </button>
            <span>
              {{element.contractor}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="organization">
          <th mat-header-cell *matHeaderCellDef>Юридична особа</th>
          <td mat-cell *matCellDef="let element" [class]="element.isContragent ? 'contragent' : 'contractor'">
            {{element.contractor}}
          </td>
        </ng-container>

        <ng-container matColumnDef="physicalWeight">
          <th mat-header-cell *matHeaderCellDef>Буряк замовника, т</th>
          <td mat-cell *matCellDef="let element" [class]="element.isContragent ? 'contragent' : 'contractor'">
            {{element.physicalWeight |
            number : '1.2-2'}}</td>
        </ng-container>

        <ng-container matColumnDef="sugarCustomers">
          <th mat-header-cell *matHeaderCellDef>Цукор замовника, т</th>
          <td mat-cell *matCellDef="let element" [class.contragent]="element.isContragent">{{element.sugarCustomers |
            number : '1.3-3'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedTotalHeaderColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedTotalColumns; when: !isContragent" [hidden]="!row.isVisible">
        </tr>
        <tr mat-row class="company"
          *matRowDef="let row; columns: ['groupHeader', 'physicalWeight', 'sugarCustomers']; when: isContragent"></tr>
      </table>
    </div>

    <div [hidden]="!detailedMode">
      <table mat-table [dataSource]="dataSourceDetails" matSort #sortDetails="matSort"
        [matSortActive]="'header-row-first'" class="width-100 mat-elevation-z8">

        <!-- Header row first group -->
        <ng-container matColumnDef="header-row-first">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.rowspan]="2" [attr.colspan]="1">
            Дата
          </th>
        </ng-container>

        <ng-container matColumnDef="header-row-second">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.rowspan]="2" [attr.colspan]="1">
            № ТТН
          </th>
        </ng-container>

        <ng-container matColumnDef="header-row-third">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.rowspan]="2" [attr.colspan]="1">
            Юридична особа
          </th>
        </ng-container>

        <ng-container matColumnDef="header-row-fourth">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.rowspan]="2" [attr.colspan]="1">
            Поле
          </th>
        </ng-container>

        <!-- Header row second group -->
        <ng-container matColumnDef="header-row-second-group">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Надходження буряку в переробку</th>
        </ng-container>

        <!-- Header row third group -->
        <ng-container matColumnDef="header-row-third-group">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Розрахунок цукру замовника</th>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Дата</th>
          <td mat-cell *matCellDef="let element">{{element.date|date:'dd.MM.yyyy hh:mm:ss'}} </td>
        </ng-container>

        <ng-container matColumnDef="numberTTN">
          <th mat-header-cell *matHeaderCellDef>№ ТТН</th>
          <td mat-cell *matCellDef="let element">{{element.numberTTN}} </td>
        </ng-container>

        <ng-container matColumnDef="organization">
          <th mat-header-cell *matHeaderCellDef>Юридична особа</th>
          <td mat-cell *matCellDef="let element">{{element.contractor}} </td>
        </ng-container>

        <ng-container matColumnDef="field">
          <th mat-header-cell *matHeaderCellDef>Поле</th>
          <td mat-cell *matCellDef="let element">{{element.field}} </td>
        </ng-container>

        <ng-container matColumnDef="physicalWeight">
          <th class="text-small" mat-header-cell *matHeaderCellDef>Кількість, т</th>
          <td mat-cell *matCellDef="let element">{{element.physicalWeight | number : '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="contamination">
          <th class="text-small" mat-header-cell *matHeaderCellDef>Забрудненість, %</th>
          <td mat-cell *matCellDef="let element">{{element.contamination | number : '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="sugarContent">
          <th class="text-small" mat-header-cell *matHeaderCellDef>Цукристість, %</th>
          <td mat-cell *matCellDef="let element">{{element.sugarContent | number : '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="sugar1TonBeets">
          <th class="text-small" mat-header-cell *matHeaderCellDef>Цукор за 1т буряка, кг</th>
          <td mat-cell *matCellDef="let element">{{element.sugar1TonBeets | number : '1.3-3'}} </td>
        </ng-container>

        <ng-container matColumnDef="conversionRate">
          <th class="text-small" mat-header-cell *matHeaderCellDef>Коефіцієнт перерахунку</th>
          <td mat-cell *matCellDef="let element">{{element.conversionRate | number : '1.3-3'}} </td>
        </ng-container>

        <ng-container matColumnDef="sugarCustomers">
          <th class="text-small" mat-header-cell *matHeaderCellDef>Цукор замовника, т</th>
          <td mat-cell *matCellDef="let element">{{element.sugarCustomers | number : '1.3-3'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedDetailedHeaderColumnsRow1; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedDetailedHeaderColumnsRow2; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedDetailedColumns;"></tr>
      </table>
    </div>

  </div>

</div>