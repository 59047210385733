<div mat-dialog-title>
    <h2>Фото</h2>
    <button type="button" mat-icon-button class="close-button" color="primary" aria-label="Close"
        [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="image-base64-preview-dialog">
        <img [src]="imageUrl" [title]="imageName">
    </div>
</div>
<div mat-dialog-actions>
    <div></div>
    <div>
        <button type="button" mat-button [mat-dialog-close]="true" cdkFocusInitial>Закрити</button>
    </div>
</div>