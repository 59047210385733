<div class="contacts-wrapper">
    <div class="contact" *ngFor="let contact of contacts; let index = index">
        <div class="contact-info">
            <div class="contact-info-title">{{contact.title}}</div>
            <div class="contact-info-address">{{contact.addressLine1}}</div>
            <div class="contact-info-address">{{contact.addressLine2}}</div>
            <div class="contact-info-phone">{{contact.phone}}</div>
        </div>
        <div class="contact-map" *ngIf="apiLoaded">
            <google-map #googleMap [options]="contact.map" height="100%" width="100%">
                <!-- [options]="marker.options" [title]="contact.address" -->
                <map-marker [position]="contact.map.center" [label]="contact.title" [id]="'marker'+index">
                    <map-info-window>
                        <!-- <div [innerHTML]="currentMarkerInfo"></div> -->
                    </map-info-window>
                </map-marker>
            </google-map>
        </div>
    </div>
</div>