import { Component, OnInit, isDevMode, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AppConfig } from './app-common/types/app-config';
import { filter, map, mergeMap } from 'rxjs/operators';
import { APP_CONFIG } from './app-common/types/injection-tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private loggerName = 'AppComponent';

  title = 'upi-portal';

  currentUser: any = {};

  constructor(
    private logger: NGXLogger,
    @Inject(APP_CONFIG) private appConfig : AppConfig,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    this.initApp(appConfig);
  }

  ngOnInit() {

    //#region  Change title according to the title for route

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild)
            route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        if (event['title']) {
          const text = event['title'];
          this.titleService.setTitle('"' + text + '" - ' + this.title);
        }
      });

    //#endregion

    if (isDevMode()) {
    }

  }

  /**
   * @param appConfig Configuration file
   * @param flag False was invoked from configuration$
   * @returns 
   */

//   appConfig: AppConfig, /
  private initApp(appConfig: AppConfig) {

    this.logger.trace(this.loggerName, "initApp", appConfig);

    // Application title
    this.titleService.setTitle(appConfig.title + (!!appConfig.environment ? ` (${appConfig.environment})` : ''));

    // Update logging config from application configuration
    this.logger.updateConfig(appConfig.logging);

  }

  isLogin() : boolean {
    return this.router.url.indexOf('login') !== -1;
  }

}
