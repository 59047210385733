<mat-menu #menu="matMenu">
  <ng-container *ngFor="let _y of years">
    <button mat-menu-item (click)="setYear(_y)" [disabled]="_y == productionYear">
      <span>{{_y}}</span>
    </button>
  </ng-container>
</mat-menu>

<div class="main-menu-wrapper">
  <div class="container">
    <ul class="menu-list" *ngIf="currentUser">
      <li class="menu-item"><a class="icon-item" routerLink="/dashboards" routerLinkActive="active">
          <span class="material-icons">home</span></a>
      </li>
      <li class="menu-item">
        <a class="icon-item" routerLink="/organization/orgchart" routerLinkActive="active">Схема підприємства</a>
      </li>
      <li class="menu-item"><a routerLink="/contragents/contracts" routerLinkActive="active">Договори</a></li>
      <li class="menu-item"><a routerLink="/contragents/exportschedule" routerLinkActive="active">Графік вивозу</a></li>
      <li class="menu-item"><a routerLink="/contragents/consignments" routerLinkActive="active">Реєстр накладних</a>
      </li>
      <li class="menu-item"><a routerLink="/maps" routerLinkActive="active">Збір і постачання буряка</a></li>
      <li class="menu-item"><a routerLink="/contragents/barter" routerLinkActive="active">Прогноз взаємозаліків</a></li>
      <!-- -->
      <li class="menu-item"><a routerLink="/services" routerLinkActive="active">Сервіси</a></li>
      <li class="menu-item year">
        <div class="year">
          <a [matMenuTriggerFor]="menu"><b>{{productionYear}}</b></a>
        </div>
      </li>
    </ul>
  </div>
</div>