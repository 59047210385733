import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { UpiCommonModule } from '@upi/common';

import { AppRoutingModule } from './app-routing.module';
import { OrganizationRoutingModule } from './organization/organization-routing.module';
import { ContragentsRoutingModule } from './contragents/contragents-routing.module';

import { AppComponent } from './app.component';
import { LOCALE_ID } from '@angular/core';
import localeUk from '@angular/common/locales/uk';

// 3-rd party modules
import { AbilityModule } from '@casl/angular';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

// 3-party components
import { Ability, PureAbility } from '@casl/ability';

// Modules
import { AppCommonModule } from './app-common/app-common.module';
import { AppMaterialModule } from './app-common/app-material.module';
import { AuthenticationModule } from './authentication/authentication.module';

// Services
import { GlobalErrorHandler } from './app-common/types/global-error-handler';
import { RequestInterceptorService } from './services/request-interceptor-service.service';
import { ContragentsModule } from './contragents/contragents.module';
import { ContactsModule } from './contacts/contacts.module';
import { LandingComponent } from './components/landing/landing.component';

// used to create fake backend
// import { fakeBackendProvider } from './services/fake-backend-interceptor';

registerLocaleData(localeUk);

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '',
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.OFF,
      disableConsoleLogging: false
    }),
    FormsModule,
    AbilityModule,

    UpiCommonModule,
    AppCommonModule,
    AppMaterialModule,
    AuthenticationModule,
    ContactsModule,
    ContragentsModule,
    OrganizationRoutingModule,
    AppRoutingModule // must be last
  ],
  providers: [
    DatePipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: "uk" },
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
