import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResultEnum } from '@upi/common';
import { AppConfig } from '../../../app-common/types/app-config';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { NGXLogger } from 'ngx-logger';
import { GeneralDataService } from 'src/app/data-services/general-data.service';
import { ServiceItem } from '@upi/data-services';
import { UserService } from 'src/app/app-common/services/user.service';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'app-order-service-dialog',
  templateUrl: './order-service-dialog.component.html',
  styleUrls: ['./order-service-dialog.component.scss']
})
export class OrderServiceDialogComponent implements OnInit {

  private loggerName = 'OrderServiceDialogComponent';

  dialogResult = DialogResultEnum;

  data: any;

  model: {
    date: Date | undefined,
    details: string,
    fileName: string
  } = {
      date: new Date(),
      details: '',
      fileName: ''
    };

  private file!: File | undefined;

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) data: any,
    private generalDataService: GeneralDataService,
    private userService: UserService,
    private dialogRef: MatDialogRef<OrderServiceDialogComponent>
  ) {
    this.data = data;
  }

  ngOnInit(): void {
  }

  filesPicked(files) {

    this.logger.trace(this.loggerName, "filesPicked(files)");

    this.file = files[0];

    if (this.file) {
      this.model.fileName = this.file.name;
    }

  }

  clear() {

    this.logger.trace(this.loggerName, "clear");

    this.file = undefined;

    this.model.fileName = '';

  }

  makeOrder(model: { date: Date | undefined, details: string, fileName: string }) {

    this.logger.trace(this.loggerName, 'makeOrder', model);

    const serviceItem: ServiceItem = new ServiceItem();
    serviceItem.type = this.data.orderType;
    serviceItem.date = model.date;
    serviceItem.notes = model.details;

    if (!!model.fileName) {

      serviceItem.fileName = model.fileName;

      this.getArrayBuffer(this.file).subscribe((arrayBuffer: ArrayBuffer) => {
        serviceItem.fileContent = btoa(
          new Uint8Array(arrayBuffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this.orderService(serviceItem, this.userService.contractorGuid);
      });
    } else {
      this.orderService(serviceItem, this.userService.contractorGuid);
    }

  }

  private orderService(serviceItem: ServiceItem, contractorGuid? : string) {
    this.generalDataService.orderService(serviceItem, contractorGuid).subscribe(
      (res) => {
        this.dialogRef.close({ dialogResult: DialogResultEnum.ok, data: true });
      },
      (error) => {
        this.logger.error(this.loggerName, error);
        alert(error.message);
      }
    );

  }

  private getArrayBuffer(file): Observable<any> {
    return from(new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }));
  }

}
