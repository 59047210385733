<div class="container">
  <div class="page-header">
    <div class="page-title">
      <h2>Графік вивозу продукції з полів</h2>
    </div>
  </div>
  <div class="page-actions">
    <div>
      <form #frmSearch="ngForm" id="frmSearch">
        <mat-form-field>
          <!-- appearance="fill"-->
          <mat-label>Юридична особа</mat-label>
          <mat-select name="contragent" [(ngModel)]="filter.contragent" (ngModelChange)="applyFilter(filter)">
            <mat-option value=''>Всі</mat-option>
            <mat-option *ngFor="let contragent of contragents" [value]="contragent">
              {{contragent}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div>
      <span class="warning-test">* Графік вивозу відображає орієнтовні значення, т.</span>
    </div>
  </div>

  <div class="scroll-container">

    <table mat-table [dataSource]="dataSource" matSort class="width-100 mat-elevation-z8">

      <ng-container matColumnDef="organization" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">Організація</th>
        <td mat-cell *matCellDef="let element"> {{element.organization}} </td>
      </ng-container>

      <ng-container matColumnDef="contractor" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">Юридична особа</th>
        <td mat-cell *matCellDef="let element"> {{element.contractor}} </td>
      </ng-container>

      <ng-container matColumnDef="field" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">Поле</th>
        <td mat-cell *matCellDef="let element"> {{element.field}} </td>
      </ng-container>

      <ng-container *ngFor="let weekColumn of weeksColumns; let wIndex = index" matColumnDef="w{{weekColumn.weekNo}}">
        <th mat-header-cell *matHeaderCellDef colspan="8">{{weekColumn.week}}</th>
      </ng-container>

      <ng-container *ngFor="let weekColumn of weeksColumns">
        <ng-container *ngFor="let daylyPlan of weekColumn.daylyPlans; let index = index"
          matColumnDef="w{{weekColumn.weekNo}}d{{index}}">
          <th class="column-vertical" mat-header-cell *matHeaderCellDef [id]="daylyPlan.day.getTime()">
            {{daylyPlan.day|date:'dd.MM.yy'}}
          </th>
          <td mat-cell *matCellDef="let element">
            {{element["w"+weekColumn.weekNo+"d"+ index]}}
          </td>
        </ng-container>
        <ng-container matColumnDef="w{{weekColumn.weekNo}}d7">
          <th class="column-vertical" mat-header-cell *matHeaderCellDef>За тиждень</th>
          <td mat-cell *matCellDef="let element">
            <b>{{element["w"+weekColumn.weekNo+"d7"]}}</b>
          </td>
        </ng-container>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: dataColumns;"></tr>

    </table>

  </div>
</div>
