import { Component, Input, OnInit } from '@angular/core';
import { BenefitItem } from '@upi/data-services';

@Component({
  selector: 'benefit-item',
  templateUrl: './benefit-item.component.html',
  styleUrls: ['./benefit-item.component.scss']
})
export class BenefitItemComponent implements OnInit {

  @Input() data! : BenefitItem;
  
  constructor() { }

  ngOnInit(): void {
  }

}
