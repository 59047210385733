import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { User } from '@upi/data-services';
import { AppAuthenticationService } from '../../../authentication/services/authentication.service';
import { AppConfig } from '../../../app-common/types/app-config';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { UserService } from '../../../app-common/services/user.service';
import { environment } from 'src/environments/default';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ContragentSelectorDialogComponent } from '../../../contragents/components/contragent-selector-dialog/contragent-selector-dialog.component';
import { DialogResultEnum } from '@upi/common';
import { LocalDataStorageService } from '../../../app-common/services/local-data-storage.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private loggerName = 'HeaderComponent';

  currentUser?: User;

  logo: string = '/assets/images/logo-ua.png';
  hotlinePhone: string;

  appVersion: string = '';

  years : number[] = [2021, 2022, 2023]; 
  productionYear : number = 2022;

  constructor(
    private logger: NGXLogger,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AppAuthenticationService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private localDataStorageService: LocalDataStorageService
  ) {

    this.logo = appConfig.logo;

    this.hotlinePhone = appConfig.hotlinePhone;

    this.userService.user$?.subscribe((data: User) => {
      this.currentUser = data;
    });

    const buildDateTime = this.datePipe.transform(new Date(environment.buildDate), 'medium');

    this.appVersion = `Version ${environment.version} build ${environment.build} from ${buildDateTime}`;

  }

  ngOnInit(): void {
    this.currentUser = this.userService.user;
  }

  changeContragent() {

    this.logger.trace(this.loggerName, "changeContragent()");

    this.dialog.open(ContragentSelectorDialogComponent, {
      width: '560px',
      disableClose: false
    }).afterClosed().subscribe((result) => {

      if (result?.dialogResult == DialogResultEnum.ok) {
        const user = this.localDataStorageService.getUser();
        user.contractor = result.contractor;
        this.userService.setUserInfo(user);

        this.reload();

      }

    });

  }

  logout() {
    this.logger.trace(this.loggerName, "logout()");
    this.authenticationService.logout();
    this.router.navigateByUrl('/login');
  }

  public async reload(): Promise<boolean> {
    const router = this.router;
    const routeReuseStrategy = router.routeReuseStrategy.shouldReuseRoute;
    const onSameUrlNavigation = router.onSameUrlNavigation;
    try {
      router.routeReuseStrategy.shouldReuseRoute = () => false;
      router.onSameUrlNavigation = 'reload';
      return await router.navigateByUrl(router.url);
    } finally {
      router.routeReuseStrategy.shouldReuseRoute = routeReuseStrategy;
      router.onSameUrlNavigation = onSameUrlNavigation;
    }
  }

}
