<div class="contact-info-groups-wrapper">
    <ng-container *ngFor="let group of contactInfoGroups">
        <div class="contact-info-group-name">{{group.name}}</div>
        <div class="contacts-wrapper container">
            <ng-container *ngFor="let item of group.persons">
                <div class="contacts">
                    <contact-info [data]="item"></contact-info>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>