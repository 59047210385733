import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContragentsRoutingModule } from './contragents-routing.module';
import { BarterListComponent } from './components/barter-list/barter-list.component';
import { FormsModule } from '@angular/forms';
import { AppCommonModule } from '../app-common/app-common.module';
import { AppControlsModule } from '../app-controls/app-controls.module';
import { ContragentsComponent } from './components/contragents/contragents.component';
import { ExportScheduleListComponent } from './components/export-schedule-list/export-schedule-list.component';
import { ConsignmentsListComponent } from './components/consignments-list/consignments-list.component';
import { ContractsListComponent } from './components/contracts-list/contracts-list.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { BenefitItemComponent } from './components/benefit-item/benefit-item.component';
import { ContragentSelectorDialogComponent } from './components/contragent-selector-dialog/contragent-selector-dialog.component';

@NgModule({
  declarations: [
    BarterListComponent,
    ContragentsComponent,
    ExportScheduleListComponent,
    ConsignmentsListComponent,
    ContractsListComponent,
    ContactsComponent,
    BenefitItemComponent,
    ContragentSelectorDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppCommonModule,
    AppControlsModule,
    ContragentsRoutingModule
  ],
  exports: [
    ContragentsRoutingModule
  ]
})
export class ContragentsModule { }
