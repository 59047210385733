import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User, OTCResponse, OTCResponseExt, MainContractor, LoginResponse } from '@upi/data-services';
import { UserService } from '../../app-common/services/user.service';
import { AuthenticateDataService } from '../../data-services/authenticate-data.service';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { LoginDialogComponent } from '../components/login-dialog/login-dialog.component';
import { LoginModel } from '../types/login-model';
import { map } from 'rxjs/operators';
import { GeneralDataService } from 'src/app/data-services/general-data.service';

@Injectable({
  providedIn: 'root'
})
export class AppAuthenticationService {

  private loggerName = 'AppAuthenticationService';

  private phonePrefix = '38';

  // private currentUserSubject: BehaviorSubject<User>;
  // public currentUser: Observable<User> | undefined;

  constructor(
    private logger: NGXLogger,
    // private localDataStorageService: LocalDataStorageService,
    private authenticateDataService: AuthenticateDataService,
    private userService: UserService,
    private dialog: MatDialog,
  ) {
    // this.currentUserSubject = new BehaviorSubject<User>(this.localDataStorageService.getUser());
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }

  private getPhoneNumber(phone: string): string {
    return this.phonePrefix + phone;
  }

  oneTimeCodeRequest(phone: string): Observable<OTCResponseExt> {

    this.logger.trace(this.loggerName, 'oneTimeCodeRequest', phone);

    //#region Fake OTCResponse
    // const response : OTCResponseExt = new OTCResponseExt(null);
    // response.superuser = true;
    // response.maincontractors = [ new MainContractor(null), new MainContractor(null)];
    // response.maincontractors[0].guid = "1";
    // response.maincontractors[0].name = "111"
    // response.maincontractors[1].guid = "2";
    // response.maincontractors[2].name = "222"
    // return of(response);
    //#endregion

    return this.authenticateDataService.oneTimeCodeRequest(this.getPhoneNumber(phone));

  }

  oneTimeCodeRequest2(phone: string, contractor: string): Observable<OTCResponse> {
    this.logger.trace(this.loggerName, 'oneTimeCodeRequest2', phone, contractor);
    return this.authenticateDataService.oneTimeCodeRequest2(this.getPhoneNumber(phone), contractor);
  }

  doLogin(login: string, password: string): Observable<LoginResponse> {

    this.logger.trace(this.loggerName, 'login', login, '*****');

    // Set fake user
    // It will be used in http-interceptor to add Authorization header
    this.userService.setUserInfo(new User({}, this.getAuthData(this.getPhoneNumber(login), password), undefined));

    return this.authenticateDataService.loginUser();

  }

  private getAuthData(login: string, password: string) {

    return window.btoa(`${login}:${password}`);
  }

  login(model: LoginModel, otcResponse: OTCResponse): Observable<User> {

    this.logger.trace(this.loggerName, 'login', model);

    // Set fake user
    // It will be used in http-interceptor to add Authorization header
    // this.currentUserSubject.next(new User({}, this.getAuthData(model.phone, model.password)));
    this.userService.setUserInfo(new User({}, this.getAuthData(this.getPhoneNumber(model.phone), model.password), model.contractorGuid));

    // model.phone.replace(/-/g, '')
    return this.authenticateDataService.authenticate(this.getPhoneNumber(model.phone), model.password, model.isSuperUser, otcResponse, model.contractorGuid);

  }

  logout() {

    this.logger.trace(this.loggerName, 'logout');

    this.userService.resetuserInfo();

  }

  openLoginModal() {
    return this.dialog.open(LoginDialogComponent, {
      width: '560px',
      disableClose: true
    });

  }

  getContragentsList(userName: string): Observable<MainContractor[]> {

    this.logger.trace(this.loggerName, 'getContragentsList', userName);

    return this.authenticateDataService.getContragentsList(userName);
  }

}
