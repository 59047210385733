import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../app-common/types/app-config';
import { NGXLogger } from 'ngx-logger';
import { CommonDataService } from './common-data.service';
import { CompanyContract, ContractsService } from '@upi/data-services';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { UserService } from '../app-common/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ContractsDataService extends CommonDataService {

  constructor(
    logger: NGXLogger,
    @Inject(APP_CONFIG) appConfig : AppConfig,
    protected userService : UserService,
    private contractsService: ContractsService,
  ) {
    super(logger, appConfig, userService);
  }

  // Список Товарно-транспотрних накладних та аналізів
  getList(productionYear : number, contractorGuid? : string): Observable<CompanyContract[]> {
    this.logger.trace(this.loggerName, "getList");
    return this.contractsService.getList(this.dataSeverUrl, contractorGuid);
  }
  
}
