import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { AppConfig } from '../../types/app-config';
import { ComponentBase } from '../../types/component-base';
import { APP_CONFIG } from '../../types/injection-tokens';

@Component({
  selector: 'app-image-base64-preview-dialog',
  templateUrl: './image-base64-preview-dialog.component.html',
  styleUrls: ['./image-base64-preview-dialog.component.scss']
})
export class ImageBase64PreviewDialogComponent extends ComponentBase implements OnInit {

  imageName : string;
  imageUrl: SafeUrl;

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) data,
    private domSanitizer: DomSanitizer

  ) {
    super(logger, appConfig, 'ImageBase64PreviewDialogComponent');
    this.imageName = data.imageData.name;
    this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,' + data.imageData.data);
  }

  ngOnInit(): void {
  }

}
