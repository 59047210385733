<div class="table-wrapper container">

  <div class="page-header">
    <div class="page-title">
      <h2>Договори</h2>
    </div>
    <div class="page-actions">
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort class="width-100 mat-elevation-z8">

    <ng-container matColumnDef="lineNumber">
      <!-- mat-sort-header -->
      <th mat-header-cell *matHeaderCellDef>№</th>
      <td mat-cell *matCellDef="let item; let i = index">{{i+1}}</td>
    </ng-container>

    <ng-container matColumnDef="fieldNumber">
      <!-- mat-sort-header -->
      <th mat-header-cell *matHeaderCellDef>Поле</th>
      <td mat-cell *matCellDef="let item">{{item.fieldNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="growingPhase">
      <!-- mat-sort-header -->
      <th mat-header-cell *matHeaderCellDef>Фаза розвитку рослини</th>
      <td mat-cell *matCellDef="let item">{{item.growingPhase}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Дата огляду</th>
      <td mat-cell *matCellDef="let item">{{(item.date|date:'dd.MM.yyyy') || '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="riscsTypes">
      <th mat-header-cell *matHeaderCellDef>Тип ризиків</th>
      <td mat-cell *matCellDef="let item">{{item.riscsTypes}} </td>
    </ng-container>

    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef>Виявлено </th>
      <td mat-cell *matCellDef="let item">{{item.details}} </td>
    </ng-container>

    <ng-container matColumnDef="recomandations">
      <th mat-header-cell *matHeaderCellDef>Рекомендації Агронома</th>
      <td mat-cell *matCellDef="let item">{{item.recomandations}} </td>
    </ng-container>


    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef>Фото</th>
      <td mat-cell *matCellDef="let item">
        <ng-container *ngIf="item?.images?.length <= 1">
          <button type="button" mat-icon-button [matTooltip]="item.images[0]?.name" [disabled]="!item?.images?.length"
            (click)="showImage(item,0)">
            <mat-icon>file_copy</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="item?.images?.length > 1">
          <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>file_copy</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item *ngFor="let image of item.images; let idx = index"
              (click)="showImage(item,idx)">
              <span [title]="item?.images[idx]?.name">Фото {{idx+1}}</span>
            </button>
          </mat-menu>

        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>