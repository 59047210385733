import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  private loggerName = 'OrganizationComponent';
  constructor(
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.logger.trace(this.loggerName, 'ngOnInit');
  }
}
