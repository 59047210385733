import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactsRoutingModule } from './contacts-routing.module';

import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http'; // FIXME remove

import { FaqComponent } from './components/faq/faq.component';
import { TeamComponent } from './components/team/team.component';
import { OrderServiceComponent } from './components/order-service/order-service.component';
import { InfoComponent } from './components/info/info.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { PresentationItemComponent } from './components/presentation-item/presentation-item.component';
import { OrderServiceDialogComponent } from './components/order-service-dialog/order-service-dialog.component';
import { AppMaterialModule } from '../app-common/app-material.module';
import { FormsModule } from '@angular/forms';
import { AgroConsultingComponent } from './components/agro-consulting/agro-consulting.component';

@NgModule({
  declarations: [
    FaqComponent,
    TeamComponent,
    OrderServiceComponent,
    InfoComponent,
    ContactsComponent,
    ContactComponent,
    ContactInfoComponent,
    PresentationItemComponent,
    OrderServiceDialogComponent,
    AgroConsultingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule, // FIXME remove
    HttpClientJsonpModule, // FIXME remove
    GoogleMapsModule,
    AppMaterialModule,    
    ContactsRoutingModule
  ],
  exports: [
    ContactsRoutingModule
  ]
})
export class ContactsModule { }
