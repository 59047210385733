import { enableProdMode, InjectionToken } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG } from './app/app-common/types/injection-tokens';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


function configListener(this:XMLHttpRequest,ev:any) {
  try {
    
    const configuration = JSON.parse(this.responseText);

    // pass config to bootstrap process using an injection token
    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: configuration }
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));

  } catch (error) {
    
    console.error(error);
  
  }

}

function configFailed(ev:any) {
  console.error('Error: retrieving config.json');
}

if (environment.production) {
  enableProdMode();
}

const request = new XMLHttpRequest();
request.addEventListener('load', configListener);
request.addEventListener('error', configFailed);
request.open('GET', environment.config);
request.send();

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));
