import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AgroConsultingItem } from '@upi/data-services/lib/types/agro-consulting-item';
import { NGXLogger } from 'ngx-logger';
import { ImageBase64PreviewDialogComponent } from 'src/app/app-common/components/image-base64-preview-dialog/image-base64-preview-dialog.component';
import { LocalDataStorageService } from 'src/app/app-common/services/local-data-storage.service';
import { UserService } from 'src/app/app-common/services/user.service';
import { AppConfig } from 'src/app/app-common/types/app-config';
import { ComponentBase } from 'src/app/app-common/types/component-base';
import { APP_CONFIG } from 'src/app/app-common/types/injection-tokens';
import { GeneralDataService } from 'src/app/data-services/general-data.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-agro-consulting',
  templateUrl: './agro-consulting.component.html',
  styleUrls: ['./agro-consulting.component.scss']
})
export class AgroConsultingComponent extends ComponentBase implements OnInit {


  data!: AgroConsultingItem[];

  displayedColumns: string[] = ['lineNumber', 'fieldNumber', 'growingPhase', 'date', 'riscsTypes', 'details', 'recomandations', 'link'];
  dataSource = new MatTableDataSource<AgroConsultingItem>();

  @ViewChild(MatSort) sort!: MatSort;


  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private userService: UserService,
    private localDataStorageService: LocalDataStorageService,
    private generalDataService: GeneralDataService,
    private dialog: MatDialog,
  ) {
    super(logger, appConfig, 'AgroConsultingComponent');
  }

  ngOnInit(): void {
    this.loadData(this.localDataStorageService.productionYear, this.userService.contractorGuid);
  }

  loadData(productionYear: number, contractorGuid?: string) {

    this.logger.trace(this.loggerName, 'loadData', productionYear, contractorGuid);

    this.generalDataService.getAgroConsultingItems(productionYear, contractorGuid).subscribe(
      (data: AgroConsultingItem[]) => {
        this.data = data;
        this.updateDataTable(this.data);
      }
    );
  }

  updateDataTable(data: AgroConsultingItem[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
  }

  //#region  Images

  // private defaultBlobType = 'application/octet-stream';

  showImage(item: AgroConsultingItem, index: number) {

    this.logger.trace(this.loggerName, 'loadImage', item, index);


    this.dialog.open(ImageBase64PreviewDialogComponent, {
      width: '600px',
      hasBackdrop: false,
      disableClose: false,
      data: {
        imageData: item.images[index]
      }
    });

  }

  //#endregion


}
