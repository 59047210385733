import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../app-common/types/app-config';
import { NGXLogger } from 'ngx-logger';
import { CommonDataService } from './common-data.service';
import { Consignment, ConsignmentsService } from '@upi/data-services';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { UserService } from '../app-common/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ConsignmentsDataService extends CommonDataService {

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    protected userService : UserService,
    private consignmentsService: ConsignmentsService,
  ) {
    super(logger, appConfig, userService);
    this.loggerName = 'ConsignmentsDataService';
  }


  // Список Товарно-транспотрних накладних та аналізів
  // yyyyMMddHHmmss
  getTtnList(dateFrom: Date, dateTo: Date, productionYear : number, contractorGuid? : string): Observable<Consignment[]> {
    this.logger.trace(this.loggerName, "getTtnList");
    return this.consignmentsService.getTtnList(this.dataSeverUrl, dateFrom, dateTo, productionYear, contractorGuid);
  }

}
