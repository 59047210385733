import { Injectable, isDevMode } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CryptoService } from '@upi/common';
import { v4 as uuid } from 'uuid';
import { User } from '@upi/data-services';

@Injectable({
  providedIn: 'root'
})
export class LocalDataStorageService {

  private loggerName = 'LocalDataStorageService';

  private keyUser = 'User';
  private keyDataServer = 'DataServer';
  private keyUserFilters = 'UserFilters';
  private keyProductionYear = 'ProductionYear';

  private delimiter = ',';
  private g11: number;
  private g12: number;
  private defaultGuid1(value: number): string {
    const res = '{' + this.d2h(value * 3 + 2) + this.delimiter + this.d2h(this.g12) + this.delimiter + this.d2h(19047) + this.delimiter + this.d2h(46421) + this.delimiter + this.d2h(3764188) + this.d2h(12022435) + '}';
    return res.toUpperCase();
  }
  private g21: number;
  private g22: number;
  private defaultGuid2(value: number): string {
    const res = '{' + this.d2h(value) + this.delimiter + this.d2h(this.g22) + this.delimiter + this.d2h(20464) + this.delimiter + this.d2h(43717) + this.delimiter + this.d2h(16033311) + this.d2h(15183155) + '}';
    return res;
  }

  constructor(
    private logger: NGXLogger,
    private cryptoService: CryptoService
  ) {
    this.delimiter = '-';
    this.g11 = 1065551523;
    this.g12 = 32086;
    this.g21 = 2779762355;
    this.g22 = 47907;
  }

  private d2h(value: number) {
    return value.toString(16);
  }

  // -> Object routines

  private getItem(key: string): any {
    let item = null;
    const itemJson = localStorage.getItem(key);
    this.logger.warn(itemJson);
    try {
      if (itemJson !== null) {
        item = JSON.parse(itemJson);
      }
    } catch (ex) {
      item = null;
      // this.logger.warn(this.loggerName, ex);
    }
    return item;
  }

  private setItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  private removeItem(key: string) {
    localStorage.removeItem(key);
  }

  // <- Common

  // -> Server Info

  public getDataServerInfo(): string | null {
    return localStorage.getItem(this.keyDataServer);
  }

  public setDataServerInfo(value: string) {
    localStorage.setItem(this.keyDataServer, value);
  }

  public removeDataServerInfo() {
    localStorage.removeItem(this.keyDataServer);
  }

  // <- Server Info

  // -> User

  public getUser(): User {

    let item = this.getItem(this.keyUser);

    try {

      if (item) {
        for (const key in item) {
          if (!isDevMode()) {
            item[key] = JSON.parse(this.cryptoService.decrypt(item[key], this.defaultGuid2(this.g21)));
          }
        }
      } else {
        item = null;
      }

    }
    catch (e) {
      this.logger.warn(this.loggerName, e);
      this.removeUser();
      item = null;
    }

    return item;

  }

  public setUser(value: User) {

    if (!value) {
      return;
    }

    const info: any = { ...value };

    for (const key in info) {
      if (!isDevMode()) {
        info[key] = this.cryptoService.encrypt(JSON.stringify((info[key])), this.defaultGuid2(this.g21));
      }
    }

    this.setItem(this.keyUser, info);

  }

  public removeUser() {
    localStorage.removeItem(this.keyUser);
  }

  // <- User


  // -> User Filters

  public getUserFilters(): string | null {
    return localStorage.getItem(JSON.parse(this.keyUserFilters));
  }

  public setUserFilters(value: string) {
    localStorage.setItem(this.keyUserFilters, JSON.stringify(value));
  }

  public removeUserFilters() {
    localStorage.removeItem(this.keyUserFilters);
  }

  // <- User Filters

  // -> Production year

  public get productionYear(): number {
    const year = localStorage.getItem(this.keyProductionYear);
    if (!year) {
      const currentDate = new Date();
      return (currentDate > new Date(currentDate.getFullYear(), 3, 1)) ? currentDate.getFullYear() : currentDate.getFullYear() - 1;
    } else {
      return +year;
    }
  }

  public setProductionYear(value: number) {
    localStorage.setItem(this.keyProductionYear, JSON.stringify(value));
  }

  public removeProductionYear() {
    localStorage.removeItem(this.keyProductionYear);
  }

  // <- Production year

}
