import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DaylyPlan, ExportSchedule, ExportScheduleItem, WeeklyPlan, Contragent, Company } from '@upi/data-services';
import { GeneralDataService } from '../../../data-services/general-data.service';
import { NGXLogger } from 'ngx-logger';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { ComponentBase } from '../../..//app-common/types/component-base';
import { APP_CONFIG } from '../../..//app-common/types/injection-tokens';
import { AppConfig } from '../../..//app-common/types/app-config';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/app-common/services/user.service';
import { LocalDataStorageService } from 'src/app/app-common/services/local-data-storage.service';

class ExportScheduleFilter {
  contragent: string = '';
}

@Component({
  selector: 'app-export-schedule-list',
  templateUrl: './export-schedule-list.component.html',
  styleUrls: ['./export-schedule-list.component.scss']
})
export class ExportScheduleListComponent extends ComponentBase implements OnInit {

  private datepipe: DatePipe = new DatePipe('en-US')

  filter: ExportScheduleFilter;
  data!: ExportSchedule[];

  // days!: number[];
  private daysColumns !: DaylyPlan[];
  weeksColumns!: WeeklyPlan[];
  private defaultColumns: string[] = ['organization', 'contractor', 'field'];
  displayedColumns: string[] = [];
  displayedColumns2: string[] = [];
  dataColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();

  // 
  _data: any[] = [];

  // DropDowns
  contragents: string[] = [];

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private generalDataService: GeneralDataService,
    private cdr : ChangeDetectorRef,
    private userService : UserService,
    private localDataStorageService : LocalDataStorageService,

  ) {
    super(logger, appConfig, 'ExportScheduleListComponent');
    this.filter = new ExportScheduleFilter();
    // this.days = Array(7).fill(0).map((x, index) => index);
  }

  ngOnInit(): void {
    this.logger.trace(this.loggerName, 'ngOnInit');
    this.loadContragents();
    this.loadData();
  }

  loadData() {

    this.logger.trace(this.loggerName, "loadData");

    this.generalDataService.getExportSchedule(this.localDataStorageService.productionYear, this.userService.contractorGuid).subscribe((data: ExportSchedule[]) => {

      this.data = data;

      this.displayedColumns = [];
      this.displayedColumns2 = [];
      this.weeksColumns = [];
      this.daysColumns = [];

      //#region transponate data

      data.forEach((exportSchedule: ExportSchedule) => {

        let row = {
          organization: exportSchedule.organization,
          contractor: exportSchedule.contractor,
          field: exportSchedule.field
        };

        exportSchedule.weeklyPlans.forEach((weeklyPlan: WeeklyPlan) => {

          if (this.weeksColumns.findIndex(el => el.weekNo == weeklyPlan.weekNo) === -1) {
            this.weeksColumns.push(weeklyPlan);
            this.displayedColumns.push(`w${weeklyPlan.weekNo}`);
          }

          weeklyPlan.daylyPlans.forEach((daylyPlan: DaylyPlan, index: number) => {

            const daylyPlanName = `w${weeklyPlan.weekNo}d${index}`;

            if (this.daysColumns.findIndex(el => el.day.getTime() == daylyPlan.day.getTime()) === -1) {
              this.daysColumns.push(daylyPlan);
              this.displayedColumns2.push(daylyPlanName);
            }

            row[daylyPlanName] = daylyPlan.value === 0 ? undefined : daylyPlan.value;

            row['dpName'] = daylyPlanName;

          });

          if ((weeklyPlan.daylyPlans.length !== 7) && (weeklyPlan.start !== undefined)) {

            let date = new Date(weeklyPlan.start);

            for (let i = 0; i < 7; i++) {

              const daylyPlanName = `w${weeklyPlan.weekNo}d${i}`;

              if (this.daysColumns.findIndex(el => el.day.getTime() == date.getTime()) === -1) {
                const daylyPlan: DaylyPlan = new DaylyPlan({ day: new Date(date), value: 0 });
                this.daysColumns.push(daylyPlan);
                this.displayedColumns2.push(daylyPlanName);
              }

              date.setDate(date.getDate() + 1);

            }

          }

          const weeklyTotalName = `w${weeklyPlan.weekNo}d7`;

          row[weeklyTotalName] = weeklyPlan.total === 0 ? undefined : weeklyPlan.total;

          if (this.displayedColumns2.findIndex(el => el == weeklyTotalName) == -1) {
            this.displayedColumns2.push(weeklyTotalName);
          }

        });

        this._data.push(row);

      });

      //#endregion

      this.displayedColumns = this.defaultColumns.concat(this.weeksColumns.sort((a, b) => a.weekNo > b.weekNo ? 1 : -1).map((el) => `w${el.weekNo}`));

      this.dataColumns = this.defaultColumns.concat(this.displayedColumns2.sort((a, b) => a > b ? 1 : -1));

      this.updateDataTable(this._data);

    });

  }

  loadContragents() {
    this.generalDataService.getOrganizationChart(this.userService.contractorGuid).subscribe((contragent: Contragent) => {
      this.contragents = [];
      contragent.subordinateCompanies.forEach((el: Company) => {
        this.contragents.push(el.name);
      });;
    })
  }


  updateDataTable(data: ExportSchedule[]) {

    this.dataSource = new MatTableDataSource(data);
    this.dataSource.filterPredicate = (data, filter) => this.filterData(data, filter);
    this.dataSource.sort = this.sort;

    this.scrollToElement();

  }

  private filterData(data: ExportScheduleItem, filter: string): boolean {

    let result = true;

    let _filter: ExportScheduleFilter = JSON.parse(filter);

    if (result && _filter.contragent) {
      result = result && (data.contractor === _filter.contragent);
    }

    return result;
  }

  applyFilter(filter: ExportScheduleFilter) {
    this.dataSource.filter = JSON.stringify(filter);
  }

  private inViewport(el) {

    let r;
    let html;

    if (!el || 1 !== el.nodeType) {
      return false;
    }

    html = document.documentElement;

    r = el.getBoundingClientRect();

    return (
      !!r
      && r.bottom >= 0
      && r.right >= 0
      && r.top >= 0
      && r.top <= html.clientHeight
      && r.left <= html.clientWidth
    );

  }

  private scrollToElement() {

    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const id = String(now.getTime());
    
    this.cdr.detectChanges();

    const el = document.getElementById(id);
    if (el && !this.inViewport(el)) {
      el.scrollIntoView({ behavior: "smooth" });
    }
    
  }

}
