import { Component, Inject, OnInit } from '@angular/core';
import { AppConfig } from '../../../app-common/types/app-config';
import { ComponentBase } from '../../../app-common/types/component-base';
import { APP_CONFIG } from '../../../app-common/types/injection-tokens';
import { ContactsService } from '../../../data-services/contacts.service';
import { NGXLogger } from 'ngx-logger';
import { ContactInfo } from '@upi/data-services';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends ComponentBase implements OnInit {

  contacts!: ContactInfo[];

  // Google Maps API
  apiLoaded: boolean = false;

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private httpClient: HttpClient,
    private contactsService: ContactsService,
  ) {
    super(logger, appConfig, 'ContactComponent');
  }

  ngOnInit(): void {

    this.httpClient.jsonp(`${this.appConfig.googleMaps.apiUrl}?key=${this.appConfig.googleMaps.apiKey}&region=UA&language=uk`, 'callback')
      .pipe(
        tap(() => {
          this.logger.trace(this.loggerName, "GOOGLE IS LOADING ...");
        }),
        map(() => {
          this.logger.trace(this.loggerName, "GOOGLE API LOADED: ", true);
          return true
        }),
        catchError((error) => {
          this.logger.error(this.loggerName, error);
          return of(false);
        })
      ).subscribe((result: boolean) => {
        this.apiLoaded = result;
        this.load();
      });

  }

  load() {

    this.logger.trace(this.loggerName, 'load');

    this.contactsService.getContactsList().subscribe((data) => {
      this.contacts = data;
    });

  }

}

