import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { UnhandledErrorDetailsComponent } from './components/unhandled-error-details/unhandled-error-details.component';
import { HeaderComponent } from './components/header/header.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from './app-material.module';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { RouterModule } from "@angular/router";
import { NumberPipe } from "./pipes/number.pipe";
import { ImageGaleryPreviewDialogComponent } from './components/image-galery-preview-dialog/image-galery-preview-dialog.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { ImageBase64PreviewDialogComponent } from './components/image-base64-preview-dialog/image-base64-preview-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MainMenuComponent,
    PageNotFoundComponent,
    UnhandledErrorDetailsComponent,
    PhoneNumberPipe,
    NumberPipe,
    ImageGaleryPreviewDialogComponent,
    ImagePreviewComponent,
    ImageBase64PreviewDialogComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AppMaterialModule,
    RouterModule,
  ],
  exports: [
    AppMaterialModule,
    HeaderComponent,
    MainMenuComponent,
    PageNotFoundComponent,
    UnhandledErrorDetailsComponent,
    PhoneNumberPipe,
    NumberPipe
  ],
  providers: [DecimalPipe]
})
export class AppCommonModule { }
