import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginComponent } from './components/login/login.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { FormsModule } from '@angular/forms';
import { AppControlsModule } from '../app-controls/app-controls.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';

@NgModule({
  declarations: [
    LoginFormComponent,
    LoginComponent,
    LoginDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    AppCommonModule,
    AppControlsModule,
    AuthenticationRoutingModule,
  ]
})
export class AuthenticationModule { }
