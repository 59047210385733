import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '@upi/data-services';
import { UserService } from '../../../app-common/services/user.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private loggerName = 'LoginComponent';

  private returnUrl!: string;
  constructor(
    private logger: NGXLogger,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  onUserLogedIn(user: User) {
    
    this.logger.trace(this.loggerName, "onUserLogedIn", user);
    
    // this.userService.setUserInfo(user);

    setTimeout(() => {
      if (!!this.returnUrl) {
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.router.navigateByUrl('/');
      }
    }, 333);

  }

}
