import { Component, Inject, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { User } from '@upi/data-services';
import { Router } from '@angular/router';
import { UserService } from '../../../app-common/services/user.service';
import { AppConfig } from '../../types/app-config';
import { APP_CONFIG } from '../../types/injection-tokens';
import { LocalDataStorageService } from '../../services/local-data-storage.service';

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  private loggerName = 'MainMenuComponent';

  currentUser?: User;
  years: number[] = [];
  productionYear!: number;

  constructor(
    private logger: NGXLogger,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private userService: UserService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
  ) {

    // this.userService.user$?.subscribe((data: User) => {
    //   this.currentUser = data;
    // });

    this.productionYear = this.localDataStorageService.productionYear;

    const maxYear = new Date().getFullYear() + 2;
    for (let i = +appConfig.startYear; i < maxYear; i++) {
      this.years.push(i);
    }

  }

  ngOnInit(): void {
    this.currentUser = this.userService.user;
  }

  setYear(year: number) {
    this.productionYear = year;
    this.localDataStorageService.setProductionYear(year);
    this.reload();
    // window.location.reload();
  }

  private reload() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

}
