<div class="header">
  <div class="container header-content">
    <div>
      <a routerLink="/" href="" class="link">
        <img alt="УКРПРОМІНВЕСТ АГРО" src="/assets/images/logo-ua-2.png" height="61">
        <span style="display: none; visibility: hidden;">{{appVersion}}</span>
      </a>
    </div>
    <div>
      <div class="hotline">
        <span>Гаряча лінія:</span><a [href]="'tel:'+hotlinePhone">{{hotlinePhone}}</a>
      </div>
    </div>
    <div class="user-menu">
      <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>person</mat-icon>
      </button>
      <span>
        {{currentUser?.firstName}} {{currentUser?.lastName}}
      </span>
      <mat-menu #menu="matMenu">
        <!-- <span mat-menu-item>
          <mat-form-field class="width-100">
            <mat-label>Виробничий рік</mat-label>
            <mat-select name="productionYear" [value]="productionYear" [required]="true">
              <mat-option *ngFor="let _year of years" [value]="_year">
                {{_year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span> -->
        <button type="button" mat-menu-item (click)="changeContragent()">
          <mat-icon>group</mat-icon>
          <span>Обрати контрагента</span>
        </button>
        <button type="button" mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Вихід</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>