import { Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateDataService } from 'src/app/data-services/authenticate-data.service';
import { LoginResponse, TokenLoginResult, User } from '@upi/data-services';
import { UserService } from 'src/app/app-common/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  private loggerName = 'LandingComponent';

  private $parametersChanged?: Subscription;

  error : any;

  constructor(
    private logger: NGXLogger,
    private activatedRoute: ActivatedRoute,
    private authenticateDataService: AuthenticateDataService,
    private userService: UserService,
    private router: Router,
  ) { 
  }


  ngOnInit(): void {

    this.error = undefined;

    this.$parametersChanged = this.activatedRoute.params.subscribe(params => {

      const token = params['token'];
      const target = params['page'];
      
      this.authenticateDataService.getAuthorizationData(token).subscribe(

        (result: TokenLoginResult) => {

          // this.userService.setUserInfo(user);
          let contractorGuid = '';

          // Need to have First name, Last name and other data
          let user: User = new User({}, this.getAuthData(result.login, result.password), contractorGuid);
          
          this.userService.setUserInfo(user);

          setTimeout(() => {
            if (!!target) {
              this.router.navigateByUrl('/' + target.replace("-", "/"));
            } else {
              this.router.navigateByUrl('/');
            }
          }, 333);

        },
        (error: any) => {
          this.logger.error(this.loggerName, error);
          this.error = error;
        }
      );

    });

  }

  ngOnDestroy(): void {
    this.$parametersChanged?.unsubscribe();
  }

  private getAuthData(login: string, password: string) {
    return window.btoa(`${login}:${password}`);
  }
}
