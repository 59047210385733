import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PinComponent } from './components/pin/pin.component';
import { FormsModule } from '@angular/forms';
import { AppCommonModule } from '../app-common/app-common.module';

@NgModule({
  declarations: [
    PinComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppCommonModule,
  ],
  exports:[
    PinComponent
  ]
})
export class AppControlsModule { }
