<div mat-dialog-title>
    <h2>Фото</h2>
    <button type="button" mat-icon-button class="close-button" color="primary" aria-label="Close" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <ng-container *ngIf="(!picturesUrls?.length || error); else dataTemplate">
        <h1 *ngIf="error">
            Помилка при отриманні даних
        </h1>
        <h1 *ngIf="!error">
            Відсутні зображення
        </h1>        
    </ng-container>
</div>
<div mat-dialog-actions>
    <div></div>
    <div>
        <button type="button" mat-button [mat-dialog-close]="true" cdkFocusInitial>Закрити</button>
    </div>
</div>

<ng-template #dataTemplate>
    <div class="pictures-wrapper">
        <ng-container *ngFor="let pictureUrl of picturesUrls">
            <image-preview class="picture-preview" [url]="pictureUrl" [allow]="true"></image-preview>
        </ng-container>
    </div>
</ng-template>