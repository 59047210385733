<div class="submenu-wrapper">
    <div class="container">
        <ul class="menu-list">
            <li class="menu-item">
                <a href="#" routerLink="orderservice" routerLinkActive="active">Замовлення послуг</a>
            </li>
            <li class="menu-item">
                <a href="#" routerLink="agroconsulting" routerLinkActive="active">Консультація агронома</a>
            </li>
            <!-- <li class="menu-item"><a href="#" routerLink="faq" routerLinkActive="active">FAQ</a></li> -->
            <li class="menu-item"><a href="#" routerLink="info" routerLinkActive="active">Інформація для вас</a></li>
            <li class="menu-item"><a href="#" routerLink="team" routerLinkActive="active">Наша команда</a></li>
            <li class="menu-item"><a href="#" routerLink="contacts" routerLinkActive="active">контакти</a></li>
        </ul>
    </div>
</div>
<router-outlet></router-outlet>