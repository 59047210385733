import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserService } from '../app-common/services/user.service';
import { AppConfig } from '../app-common/types/app-config';
import { APP_CONFIG } from '../app-common/types/injection-tokens';
import { CommonDataService } from '../data-services/common-data.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class ImageService extends CommonDataService {

  loggerMame = 'ImageService';

  constructor(
    protected logger: NGXLogger,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    protected userService : UserService,
    private http: HttpClient
  ) {
    super(logger, appConfig, userService);
  }

  getImage(url: string) {
    this.logger.trace(this.loggerMame, 'getImage', url);
    return this.http.get(`${url}`, { responseType: 'blob' });
  }

}
